.container {
    text-align: center;
    width: 100vw;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.container h1 {
    font-family: var(--componentFontFamily);
    margin-bottom: 20px;
}

.container p {
    font-size: 18px;
    font-family: var(--sortedHeaderFontFamily);
}
