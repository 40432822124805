.hide {
	display: none;
}

.container {
	position: relative;
	padding-left: 0.1em;
}

.containerGrid {
	display: flex;
}

.avatar {
	height: 2em;
	width: 2em;
	margin-right: 0.9em;
	object-fit: cover;
	cursor: pointer;
	transition: border .1s ease-in;
	cursor: default;
}

.name {
	/*max-width: 90%;*/
	/*white-space: pre;*/
	/*overflow: hidden;*/
	/*text-overflow: ellipsis;*/
}

.avatarList {
	height: 1.8em;
	width: 1.8em;
	margin-right: 0.9em;
	object-fit: cover;
	cursor: pointer;
	transition: border .1s ease-in;
	cursor: default;
}

.avatarEditable {
	height: 2em;
	width: 2em;
	margin-right: 0.9em;
	object-fit: cover;
	cursor: pointer;
	transition: border .1s ease-in;
}

.avatarEditable:hover {
	box-shadow: 0 0 0 0.5pt #e0e0e0;
}

.avatarEditable.disabled {
	cursor: default;
}

.avatar.disabled {
	cursor: default;
}

.avatarList.disabled {
	cursor: default;
}

.emptyAvatar {
	height: 2em;
	width: 2em;
	margin-right: 0.9em;
	object-fit: cover;
	cursor: pointer;
	opacity: 0.4;
	transform: scale(0.9);
}

.emptyAvatar.disabled {
	cursor: default;
}

.emptyAvatarList {
	height: 2em;
	width: 2em;
	margin-right: 0.9em;
	object-fit: cover;
	cursor: pointer;
	opacity: 0.4;
	transform: scale(0.9);
}

.emptyAvatarList.disabled {
	cursor: default;
}

.deleteUserFromTaskButton {
	position: absolute;
	text-align: center;
	top: -0.2em;
	width: 0.9em;
	height: 0.9em;
	border: 0;
	border-radius: 9999em;
	opacity: 0;
	transform: rotate(45deg);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	user-select: none;
	cursor: pointer;
	transition: opacity var(--fastAnimationDuration) ease-in;
	outline: none;
	z-index: 2;
}

.deleteUserFromTaskButton::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 9999em;
}

.deleteUserFromTaskButton .closeIcon {
	position: relative;
	bottom: 0.2em;
	width: 65%;
}

.deleteUserFromTaskButton .closeIcon {
	fill: var(--declineColor);
}

.avatar:hover + .deleteUserFromTaskButton {
	opacity: 1;
}

.avatarList:hover + .deleteUserFromTaskButton {
	opacity: 1;
}

.deleteUserFromTaskButton:hover {
	opacity: 1;
}

.deleteUserFromTaskButton.initiator {
	left: 1.35em;
}

.deleteUserFromTaskButton.verificator {
	left: 4.45em;
}

.deleteUserFromTaskButton.implementer {
	left: 7.6em;
}

.deleteUserFromTaskButton.grid{
	margin-left: 25px;
	top: 5px;
}

.notChanged {
	border: 1.25px solid var(--accentGray);
}

.newArrow {
	position: absolute;
	top: 0.86em;
	margin-left: -1.1em;
	height: 0.294375em;
	transform: rotate(270deg);
}

.newArrow path {
	stroke: var(--inputGray);
}

.container .roleUserChanger {
	position: absolute;
	top: 2.95em;
	width: 16.5625em;
	z-index: 3;
}

.roleUserChangerGrid {
	position: absolute;
	top: 2.95em;
	width: 16.5625em;
	z-index: 3;
}

.roleUserChanger.initiator {
	left: -0.2em;
}

.roleUserChanger.verificator {
	left: 2.75em;
}

.roleUserChanger.implementer {
	left: 5.7em;
}

.roleUserChangerGrid.initiator {
	top: 120%;
	left: 42%;
}

.roleUserChangerGrid.verificator {
	top: 120%;
	left: 53.6%;
}

.roleUserChangerGrid.implementer {
	top: 120%;
	left: 65%;
}

.taskColumns{
	display: flex;
	flex-basis: 15%;
	cursor: pointer;
	padding-left: 5px;
	align-items: center;
	background-color: var(--componentBackgroundColor);
}

.taskColumns  {
	/*max-width: 40%;*/
	white-space: pre;
	text-overflow: ellipsis;
	overflow: hidden;
}

.taskColumns p {
	padding-right: 5px;
	white-space: pre;
	text-overflow: ellipsis;
	overflow: hidden;
}

.taskColumns:not(:last-child){
	border-right: 1px solid var(--darkGreyBackground);
	margin-right: 10px;
}

.noUser {
	color: #464646;
	font-weight: 200;
}
