.container {
	position: relative;
}

.container > * {
	display: inline-block;
}

.projectInformation {
	position: absolute;
	top: 0;
}

.projectLogo {
	font-size: 25px;
	margin-right: 0.56em;
}

.projectInformation .workspaceNameChanger,
.projectInformation .hideElement {
	font-size: 30px;
	margin-right: 0.5em;
	min-width: 1em;
	padding: 0 0.5em 0 0;
	font-weight: 600;
	line-height: 35px;
	font-variation-settings: var(--fontWeightVariableBold);
	font-family: var(--ralewayBold);
	border: 2px solid #ffffff00;
}

.projectInformation .hideElement {
	position: absolute;
	overflow: hidden;
	white-space: pre;
	opacity: 0;
	pointer-events: none;
}

.projectInformation .workspaceNameChanger {
	display: inline-block;
	position: relative;
	border-radius: var(--defaultBorderRadius);
	outline: none;
	z-index: 1;
	transition: border var(--superFastAnimationDuration) ease-in,
		padding var(--superFastAnimationDuration) ease-in;
}

.projectInformation .workspaceNameChanger.isEdit {
	padding: 0 0.25em 0 0.25em;
	border: 2px solid var(--inputGray);
}

.projectInformation .gitHubLink {
	display: inline-block;
	position: absolute;
	top: 0.25em;
	height: 2em;
}

.container .statusSelector {
	position: absolute;
	bottom: -8px;
	font-size: 16px;
	min-width: 7.875em;
	font-variation-settings: var(--fontWeightVariableNormal);
}

.statusSelector button {
	position: relative;
	padding-left: 0.2em;
	padding-right: 2.25em;
	white-space: nowrap;
	box-shadow: none;
	transition: left var(--superFastAnimationDuration) ease-in,
		width var(--superFastAnimationDuration) ease-in,
		padding-left var(--superFastAnimationDuration) ease-in;
	display: flex;
}

.statusSelector button > svg {
	position: absolute;
	top: 0.9em;
	right: 0.35em;
}

.statusSelector button.openMenu {
	top: 1px;
	left: 0.5em;
	width: 100%;
	text-align: left;
	padding-left: 0.35em;
	padding-right: 0.35em;
	border-bottom: 1px solid var(--lightGrayBorder);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 3;
}

.projectStatusChangerList {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0.5em;
	width: 100%;
	white-space: nowrap;
	padding-top: calc(2em + 2px);
	z-index: 2;
	border-bottom-left-radius: var(--smallBorderRadius);
	border-bottom-right-radius: var(--smallBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	background-color: var(--componentBackgroundColor);
}

.projectStatusChangerList > .projectStatusChangerListElement {
	padding: 0.4666em 0.35em;
	font-variation-settings: var(--fontWeightVariableLight);
	cursor: pointer;
}

.projectStatusChangerList > .projectStatusChangerListElement:not(:last-child) {
	border-bottom: 1px solid var(--lightGrayBorder);
}



@media screen and (max-width: 1600px){
	.workspaceNameChanger {
		white-space: nowrap;
	}
	.projectInformation {
		max-width: 95%;
	}
	.workspaceNameChanger {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 50vw;
	}
}

@media screen and (max-width: 600px){
	.gitHubLink {
		display: none !important;
	}
}

@-moz-document url-prefix() {
	.projectInformation .workspaceNameChanger {
		/*font-family: var(--componentFontFamily);*/
		font-weight: 500;
	}
}
