.wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: var(--backgroundGray);
	overflow-y: hidden;
	overflow-x: hidden;
}



@media screen and (max-width: 1600px){
	.wrapper {
		position: relative;
		height: 100%;
		width: 100%;
		background-color: var(--backgroundGray);
		overflow-y: hidden;
	}
}

@media screen and (max-width: 500px){
	.wrapper {
		/*height: 91vh;*/
		/*padding-bottom: 9vh;*/
	}
}
