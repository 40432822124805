.taskComment{
	padding-top: 10px;
}
.divred{
    width: 95%;
    min-height: 50px;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    background-color: white;
    border-bottom: 1px solid rgb(194, 192, 192);
    border-top: 1px solid rgb(194, 192, 192);
}
.avaImg{ 
    margin: 10px 5px ;
    object-fit: cover;
    height: 40px;
    width: 40px;
    border-radius: 20px;
}
.comentBodyBlock{
    margin-left:15px;
    margin-right: 20px;
    margin-bottom: 10px;
}
.nameUser{
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    color:rgb(228, 42, 188);
    margin-bottom: 5px;
}
.commentText{
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    line-height: 18px;
    margin-bottom: 5px;
}
.footerComment{
    display: flex;

    justify-content: space-between;
}

.imgFocusComment{
    padding: 2px;
    height: 10px;
    width:10px;
    transform: rotate(180deg);
    background-color: rgb(202, 202, 202);
    border-radius: 50px;
    cursor: pointer;
}
.dateComment{
    font-size: 10px;
    color: rgb(202, 202, 202);
}
.dateComment span{
    font-size: 10px;
    color: rgb(124, 124, 223);
}
.addCommentBlock{
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 550px; */
    background-color: white;
}
.avaBlock img{
    margin: 5px;
    flex: 1 1 auto;
}
.InputComentBlock{
    /* flex: 1 1 65%; */
    width: 400px;

    border-radius: 5px;
    padding: 5px;
}
.InputComent:focus{
    border: 1px white solid;

}
.btnPostCommentBlock{
    flex: 0 1 auto;
}
.btnPostComment{
    background: url("https://cdn.pngsumo.com/sent-icon-png-image-free-download-searchpngcom-sent-png-715_657.png") ;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
}
.btnPostComment:active{
    margin-left: 10px;
}
