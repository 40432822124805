.container {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: 167px;
    height: 32px;
    border-radius: var(--defaultBorderRadius);
    /*box-shadow: var(--defaultBoxShadow);*/
    cursor: pointer;
    top: 0;
    position: relative;
}

.container button:hover {
    background-color: var(--lightGrayBorder);
}

.container button {
    line-height: 1;
    font-size: 1em;
    font-family: var(--dropdownFontFamily);
    font-variation-settings: var(--fontWeightVariableLight);
    border: none;
    border-radius: var(--defaultBorderRadius);
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    outline: none;
    cursor: pointer;
    transition: background-color .1s ease-in;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 100%;
    padding: 0.625em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*z-index: 2;*/
}

.container button p {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.arrow {
    vertical-align: middle;
    height: 0.322em;
    margin-left: 1em;
    transform: rotate(0);
    transition: transform var(--fastAnimationDuration) ease-in,
    opacity var(--fastAnimationDuration) ease-in;
}

.arrow path {
    stroke: var(--darkTextColor);
}

.arrow.isOpen {
    transform: rotate(180deg);
}

.arrow.hideWithoutHover {
    opacity: 0;
}

.window {
    top: 32px;
    line-height: 1;
    font-size: 1em;
    font-family: var(--dropdownFontFamily);
    font-variation-settings: var(--fontWeightVariableLight);
    border: none;
    border-radius: var(--defaultBorderRadius);
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    outline: none;
    cursor: pointer;
    transition: background-color .1s ease-in;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 100%;
    /*padding: 0.625em;*/
    text-align: left;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    align-items: flex-start;
}

.options {
    max-height: 185px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

/*.window p:not(:last-child) {*/
/*    margin-bottom: 10px;*/
/*}*/

.window div:not(:first-child) {
    border-top: 1px solid var(--lightGrayBorder);
}

.item {
    transition: background-color .1s ease-in;
    width: 100%;
}

.item:hover {
    background-color: var(--lightGrayBorder);
}

.item p {
    padding: 0.625em;
    text-overflow: ellipsis;
    overflow: hidden;
}

.searchBar {
    margin: 10px 20px 10px 10px;
    /*margin-right: 30px;*/
    padding-left: 10px;
    height: 32px;
    background-color: var(--darkGreyBackground);
    border: 1px solid #ffffff00;
    color: var(--inputDark);
    transition: background-color var(--superFastAnimationDuration) ease-in,
    color var(--superFastAnimationDuration) ease-in,
    border var(--superFastAnimationDuration) ease-in;
    width: 90%;
}

.searchBar > object {
    filter: var(--filterConverterForDarkSVGtoDarkGray);
    transition: filter var(--superFastAnimationDuration) ease-in;
}

.searchBar::placeholder {
    color: var(--inputDark);
    transition: color var(--superFastAnimationDuration) ease-in;
}

.searchBar.focus {
    background-color: var(--componentBackgroundColor);
    color: var(--darkTextColor);
    border: 1px solid var(--accentGray);
}

.searchBar.focus > object {
    filter: var(--filterConverterForDarkSVGtoGray);
}

.searchBar.focus input::placeholder {
    color: var(--darkTextColor);
}

.notFound {
    width: 100%;
    text-align: center;
    padding: 5px;
}

.selected {
    background-color: var(--lightGrayBorder);
}
