.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: var(--modalWindowBackground);
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
}

.container {
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 10001;
    left: 0;
    top: 0;
    pointer-events: none;
}

.content {
    /*height: 200px;*/
    width: 443px;
    background-color: white;
    border-radius: var(--defaultBorderRadius);
    margin: auto;
    padding: 20px;
}

.header {
    font-size: 22px;
    font-family: var(--sfuiBold);
}

.body {
    font-family: var(--headerFontFamily);
    margin-top: 25px;
    font-size: 16px;
}

.button {
    padding: 0 30px;
    margin-top: 25px;
    float: right;
}
