.container {
    border-radius: var(--defaultBorderRadius);
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    position: absolute;
    z-index: 3;
    cursor: auto;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity, transform 50ms ease-in-out;
}

.containerGrid {
    border-radius: var(--defaultBorderRadius);
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    position: absolute;
    z-index: 3;
    width: 230px;
    /*padding: 20px 0 0 0;*/
    top: 60px;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity, transform 50ms ease-in-out;
}

.containerGrid::after {
    content: '';
    position: absolute;
    left: 0.5em;
    top: -1.1em;
    border: 0.5em solid transparent;
    border-left: 0.9em solid var(--componentBackgroundColor);
    filter: drop-shadow(var(--defaultBoxShadow));
    z-index: -1;
    transform: rotate(270deg);
}

.containerGrid::before {
    content: '';
    position: absolute;
    left: 0.6em;
    top: 0;
    height: 1em;
    width: 2em;
    background-color: var(--componentBackgroundColor);
    z-index: 4;
}

.containerGrid h4 {
    padding: 1.1em 0.735em 0;
    font-size: 15px;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    margin-bottom: 1.3em;
}

.show {
    opacity: 1;
    transform: scale(1);
}

.container::after {
    content: '';
    position: absolute;
    left: 0.5em;
    top: -1.1em;
    border: 0.5em solid transparent;
    border-left: 0.9em solid var(--componentBackgroundColor);
    filter: drop-shadow(var(--defaultBoxShadow));
    z-index: -1;
    transform: rotate(270deg);
}

.container::before {
    content: '';
    position: absolute;
    left: 0.6em;
    top: 0;
    height: 1em;
    width: 2em;
    background-color: var(--componentBackgroundColor);
    z-index: 4;
}

.container h4 {
    padding: 1.1em 0.735em 0;
    font-size: 15px;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    margin-bottom: 1.3em;
}

.fancyLabelContainer {
    position: relative;
    padding: 0 0.735em;
    margin-bottom: 0.5625em;
}

.fancyLabelContainer .fancyTextInputLabel {
    position: absolute;
    font-size: 0.6875em;
    top: -0.75em;
    left: 1.75em;
    line-height: 1;
    padding: 0.27273em 0.454545em;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    background-color: var(--componentBackgroundColor);
    color: var(--accentGray);
}

.fancyLabelContainer .fancyTextInput {
    width: 100%;
    line-height: 2.2;
    padding: 0 1em;
    border: 1px solid var(--accentGray);
    border-radius: var(--defaultBorderRadius);
    outline: none;
}

@media not all and (min-resolution:.001dpcm){
    @media {
        .fancyLabelContainer .fancyTextInput {
            line-height: 3;
        }
    }
}

.usersList {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 0.5em;
    /*border-bottom: 1px solid var(--accentGray);*/
}

.maxHeightWithButton {
    max-height: 190px;
}

.maxHeightWithoutButton {
    max-height: 200px;
    font-size: 16px;
}

.usersListLeads {
    margin-bottom: 5px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.userListElement {
    padding: 0.25em 0.735em;
    cursor: pointer;
}

.userListElement:hover {
    background-color: var(--lightGrayBorder);
}

.userListElement .userPhoto {
    display: inline-block;
    vertical-align: middle;
    height: 2em;
    width: 2em;
    margin-right: 0.5625em;
}

.userListElement p {
    display: inline-block;
    font-size: 0.8125em;
    vertical-align: middle;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    color: var(--darkBackgroundColor);
}

.userListElementSelected {
    padding: 0.25em 0.735em;
    cursor: pointer;
    background-color: var(--lightGrayBorder);
}

.userListElementSelected:hover {
    background-color: var(--lightGrayBorder);
}

.userListElementSelected .userPhoto {
    display: inline-block;
    vertical-align: middle;
    height: 2em;
    width: 2em;
    margin-right: 0.5625em;
}

.userListElementSelected p {
    display: inline-block;
    font-size: 0.8125em;
    vertical-align: middle;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    color: var(--darkBackgroundColor);
}

.addUserButton {
    width: 100%;
    font-size: 0.8125em;
    line-height: 3.46154;
    padding: 0 1.30769em;
    text-align: left;
    font-family: var(--componentFontFamily);
    font-weight: 500;
    color: var(--accentCrimsonColor);
    border: 0;
    border-radius: var(--defaultBorderRadius);
    background-color: var(--componentBackgroundColor);
    outline: none;
    cursor: pointer;
}

.plusButton {
    display: inline-block;
    font-size: 1.8em;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 0.92em;
    padding: 0 0.2em;
    margin-right: 0.7em;
    font-weight: 300;
    color: var(--componentBackgroundColor);
    border-radius: 9999em;
    background-color: var(--accentCrimsonColor);
}

.closeIcon {
    width: 15px;
    /*transform: rotate(45deg);*/
    position: absolute;
    right: 14px;
    top: 20px;
    cursor: pointer;
    transition: opacity 100ms ease-in-out;
    opacity: 0.8;
}

.closeIcon img {
    width: 12px;
}

.closeIcon:hover {
    opacity: 0.6;

}

.animatedUserChangerEnterActive {
    opacity: 0;
    transform: scale(0.3);
}

.animatedUserChangerEnterDone {
    opacity: 1;
    transition: opacity, transform 25ms;
    transform: scale(1);
}

.animatedUserChangerExit {
    opacity: 1;
    transform: scale(1);
}

.animatedUserChangerExitActive {
    opacity: 1;
    transform: scale(1);
    transition: opacity, transform 25ms ease-in;
}

.animatedUserChangerExitDone {
    opacity: 1;
    transform: scale(0.3);
}

.allUsersButton {
    border-radius: 0;
    width: 100%;
    padding: 0 5px 0 5px;
    height: 32px;
    color: var(--accentCrimsonColor);
    background-color: #efefef;
    transition: color 100ms ease-in-out;
    border-bottom: 1px solid white;
}

.allUsersButton:hover {
    color: #f592ab;
    background-color: #efefef;
}

.resetFilterButton {
    border-radius: 0 0 6px 6px;
    width: 100%;
    padding: 0 5px 0 5px;
    height: 34px;
    color: var(--accentCrimsonColor);
    background-color: #efefef;
    transition: color 100ms ease-in-out;
}

.resetFilterButton:hover {
    color: #f592ab;
    background-color: #efefef;
}
