.container {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /*margin-top: auto;*/
    /*margin-bottom: auto;*/
}

.name {
    font-size: 30px;
}

.body {

    margin: auto;
}

.body h2 {
    margin-bottom: 20px;
}

.header {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100px;
    box-shadow: var(--defaultBoxShadow);
}

.input {
    background: none;
    padding: 10px 20px;
    width: 400px;
    font: normal normal normal 17px/24px var(--componentFontFamily);
    color: #333;
    text-align: left;
    opacity: 1;
    border-radius: 12px;
    border: 1px solid #002cfc;
}

.input:focus {
    outline: none;
}

.button {
    cursor: pointer;
    font-size: 20px;
    color: #333;
    text-align: center;
    opacity: 1;
    padding: 10px 25px;
    background: none;
    border-radius: 12px;
    border: 1px solid #333;
    margin-left: 20px;
    transition: border, color var(--superFastAnimationDuration) ease-in-out;
    user-select: none;
}

.button:hover {
    border: 1px solid #002cfc;
    color: #002cfc;
}

.button:focus {
    outline: none;
}

.roboto {
    color: #002cfc;
    background: none;
    cursor: pointer;
    width: 300px;
    outline: none;
    border: none;
    user-select: none;
    font-size: 18px;
    font-family: Roboto, serif;
}

.exitButton {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 5px 15px;
}

.copyIcon {
    width: 24px;
    height: auto;
    opacity: 1;
    transition: opacity 100ms ease-in-out;
    cursor: pointer;
    position: absolute;
}

.copyIcon:hover {
    opacity: 0.5;
}

.copyIcon:hover + .info{
    opacity: 1;
}

.copyContainer {
    position: relative;
    margin-left: 10px;
}

.info {
    bottom: -35px;
    left: 0;
    position: absolute;
    user-select: none;
    pointer-events: none;
    color: white;
    border-radius: 6px;
    width: 100px;
    padding: 5px;
    background: var(--inputDark);
    font-family: var(--headerFontFamily);
    opacity: 0;
    transition: opacity 100ms ease-in-out;
}

@media screen and (max-width: 600px){
    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .body h2 {
        text-align: center;
    }

    .input {
        width: 90vw;
    }

    .input[placeholder] {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .button {
        margin-top: 20px;
    }

}


@media screen and (max-width: 1600px){
    .name {
        margin-left: 40px;
    }
}
