.tab {
	font-size: 18px;
	line-height: 60px;
	padding: 0 36px;
	color: var(--inputGray);
	border-top: 1px solid var(--borderDark);
	transition: background-color var(--superFastAnimationDuration) ease-in,
		color var(--superFastAnimationDuration) ease-in;
	cursor: pointer;
	user-select: none;
	
}

.tab:hover {
	color: var(--componentBackgroundColor);
	background-color: var(--darkBackgroundHoverColor);
}

.selectedTab {
	color: var(--componentBackgroundColor);
	background-color: var(--darkBackgroundHoverColor);
}

.lockedTab {
	position: absolute;
	height: 18px;
	width: 18px;
	right: 11%;
	margin-top: 20px;
}

@media screen and (max-width: 1600px){
	.lockedTab {
		right: 12.5%;
	}
}
