.body {
    height: 23px;
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    /*width: 80px;*/
    text-align: center;
}

.container {
    height: 23px;
    width: auto;
    padding: 0 10px 0 10px;
    border: 1px dashed #C5C5C5;
    border-radius: 4px;
    font-size: 13px;
    font-family: var(--componentFontFamily);
    line-height: 23px;
    color: var(--standartBlackColor);
    transition: background-color var(--superFastAnimationDuration) ease-in-out;
}

.containerDisabled {
    height: 23px;
    width: auto;
    padding: 0 10px 0 10px;
    border: 1px dashed #C5C5C5;
    border-radius: 4px;
    font-size: 13px;
    font-family: var(--componentFontFamily);
    line-height: 23px;
    color: var(--standartBlackColor);
    cursor: default;
}

.container:hover {
    background-color: var(--lightGrayBorder);
}

.name {
    color: var(--standartBlackColor);
    line-height: 23px;
    margin: 0 10px 0 10px;
    text-align: center;
    font-size: 13px;
    font-family: var(--componentFontFamily);
}

.options {
    position: relative;
    z-index: 8;
    height: 23px;
    background-color: #FFF;
    border-radius: 4px;
    border-bottom: 1px dashed #C5C5C5;
    border-left: 1px dashed #C5C5C5;
    border-right: 1px dashed #C5C5C5;
}

.options:hover {
    background-color: var(--lightGrayBorder);
}

.topicsList {
    position: relative;
    z-index: 2;
    background-color: #FFF;
    border-radius: 4px;
}
