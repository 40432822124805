.workspaceLoader {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: var(--componentBackgroundColor);
	z-index: 3;
}

.workspaceLoader .spinner {
	pointer-events: none;
	width: 5.5em;
	height: 5.5em;
	border: 0.7em solid transparent;
	border-color: var(--accentGray);
	border-top-color: var(--accentCrimsonColor);
	opacity: 0.7;
	border-radius: 50%;
	animation: loadingSpinnerRotate 1s linear infinite;
}

@keyframes loadingSpinnerRotate {
	100% {
		transform: rotate(360deg);
	}
}

.container {
	padding: 36px 32px 0;
	vertical-align: top;
}

.optionsGroup {
	position: relative;
	margin-bottom: 41px;
}

.optionsGroup > * {
	vertical-align: top;
}

.displaySelector {
	height: 2em;
	width: 4em;
}

.displaySelector,
.dropdownButton {
	font-size: 16px;
	display: inline-block;
	margin-right: 1.5em;
}

.dropdownButton > button {
	text-align: justify;
	width: 100%;
}

.dropdownButton > button > svg {
	position: absolute;
	right: 0.625em;
	top: 0.9em;
}

.dropdownButton > button.dropdownButtonIsOpen {
	position: relative;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 4;
	box-shadow: none;
}

.dropdownButton.taskStatusFilter {
	width: 9.75em;
}

.dropdownButton.taskTypeFilter {
	width: 10.375em;
}

.dropdownButton.taskDateFilter {
	width: 10.1875em;
}

.dropdownButton.taskDateFilter button {
	padding-right: 40px;
}

.dropdownButton.taskThemeFilter {
	width: 8.75em;
	z-index: 3;
}

.dropdownButton.userTasksFilter {
	width: 10.75em;
}

.dropdownButton .dropdownButtonOptionList {
	position: absolute;
	top: 0;
	width: 100%;
	padding-top: calc(2.4em + 1px);
	font-family: var(--componentFontFamily);
	font-size: 0.8125em;
	font-weight: 500;
	border-radius: var(--defaultBorderRadius);
	overflow: hidden;
	background-color: var(--componentBackgroundColor);
	z-index: 2;
	box-shadow: var(--defaultBoxShadow);
}

.dropdownButtonOptionList > .dropdownButtonOptionListElement {
	padding: 0.6923em 0.7692em;
	border-top: 1px solid var(--lightGrayBorder);
	cursor: pointer;
}

.dropdownButtonOptionList > .dropdownButtonOptionListElement:hover {
	background-color: var(--lightGrayBorder);
}

.accentButton {
	position: absolute !important;
	top: 0;
	right: 0;
	font-size: 16px;
}

.overlay{
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	cursor: pointer;
}

.locked {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
}

.lockedWindow {
	display: flex;
	height: 100%;
	width: 100%;
	background-color: var(--componentBackgroundColor);
}

.lockedBody {
	margin: auto;
}

.lockedBody h1 {
	font-size: 40px;
	color: var(--accentGray);
	margin-top: 50px;
	text-align: center;
}

.lockIcon {
	width: 500px;
	height: 500px;
	filter: var(--filterConverterForDarkSVGtoGray);
	opacity: 0.4;
}

.accentButtonSmall {
	display: none;
}

@media screen and (max-width: 1500px) {
	.accentButton{
		position: relative !important;
	}
}

@media screen and (max-width: 1600px) {
	.optionsGroup {
		display: none;
		margin-bottom: 21px;
	}
	.displaySelector,
	.dropdownButton {
		font-size: 16px;
		display: inline-block;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 800px) {
	.container {
		display: none;
	}
}
