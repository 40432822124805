.calendar{
    position: absolute;
	top: 38px;
    left: -280px;
	color: black;
}

.dateCalendar{
	position: absolute;
	top: 38px;
	left: -226px;
	color: black;
}

.date {
	/*width: 115px;*/
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background-color: var(--componentBackgroundColor);
	margin: 0 5px 0 6px;
	padding: 5px 10px 5px 6px;
	border-radius: 6px;
}

.date p {
	font-weight: 400;
}

.todayDate p {
	font-weight: 400;
}

.date > p:last-child {
	margin-left: 5px;
}

.todayDate {
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	margin: 0 5px 0 5px;
	padding: 5px 10px 5px 6px;
	background: #fff7e6;
	border-radius: var(--defaultBorderRadius);
}

.todayDate > p:last-child {
	margin-left: 5px;
}

.todayDate img {
	transition: opacity .1s ease-in-out;
}

.todayDate img:hover {
	opacity: 0.5;
}

.todayDate img {
	align-self: center;
}

.date img {
	transition: opacity .1s ease-in-out;
}

.date img:hover {
	opacity: 0.5;
}

.date img {
	align-self: center;
}

.date.deadline{
	margin-left: 12px;
	/* width: 90px; */
	padding: 2px 5px 2px 5px;
	border-radius: var(--smallBorderRadius);
	background-color: var(--deadlineColor);
	color: var(--componentBackgroundColor);
}

.dateDisabledToday {
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background: #fff7e6;
	padding: 5px 5px 5px 12px;
	transition: background-color .1s ease-in-out;
	cursor: default;
}

.dateDisabledToday > p:last-child {
	margin-left: 5px;
}

.dateDisabledToday img {
	align-self: center;
}

.dateDisabledToday.deadline{
	margin-left: 12px;
	width: 90px;
	padding: 2px 5px 2px 5px;
	border-radius: var(--smallBorderRadius);
	background-color: var(--deadlineColor);
	color: var(--componentBackgroundColor);
}

.dateDisabled {
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background-color: var(--componentBackgroundColor);
	padding: 5px 5px 5px 12px;
	transition: background-color .1s ease-in-out;
	cursor: default;
}

.dateDisabled > p:last-child {
	margin-left: 5px;
}

.dateDisabled img {
	align-self: center;
}

.dateDisabled.deadline{
	margin-left: 12px;
	padding: 2px 5px 2px 5px;
	border-radius: var(--smallBorderRadius);
	background-color: var(--deadlineColor);
	color: var(--componentBackgroundColor);
}

.todayDateModal {
	width: 100%;
	height: 100%;
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background: #fff7e6;
}

.todayDateModal > p:last-child {
	margin-left: 5px;
}

.todayDateModal img {
	align-self: center;
	height: auto;
}

.todayDateModal p:last-child {
	margin-left: 10px;
	align-self: center;
}


.todayDateModal.deadline{
	padding: 2px 5px 2px 5px;
	border-radius: var(--smallBorderRadius);
	background-color: var(--deadlineColor);
	color: var(--componentBackgroundColor);
}

.dateModal {
	width: 100%;
	height: 100%;
	display: flex;
	/*gap: 5px;*/
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
}

.dateModal > p:last-child {
	margin-left: 5px;
}

.dateModal img {
	align-self: center;
	height: auto;
}

.dateModal p:last-child {
	margin-left: 8px;
	align-self: center;
}


.dateModal.deadline{
	padding: 2px 5px 2px 5px;
	border-radius: var(--smallBorderRadius);
	background-color: var(--deadlineColor);
	color: var(--componentBackgroundColor);
}

.emptyDateList {
	display: flex;
	padding-left: 12px;
	width: 128px;
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background-color: var(--componentBackgroundColor);
	border-radius: 6px;
}

.emptyDateList img {
	transition: opacity .1s ease-in-out;
}

.emptyDateList img:hover {
	opacity: 0.5;
}

.emptyDateList img {
	align-self: center;
}

.emptyDateListDisabled {
	cursor: default;
	display: flex;
	padding-left: 12px;
	width: 128px;
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background-color: var(--componentBackgroundColor);
}

.emptyDateListDisabled img {
	transition: opacity .1s ease-in-out;
}

.emptyDateListDisabled img {
	align-self: center;
}

.emptyDateModal {
	border-radius: 4px;
	display: flex;
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
	background-color: var(--componentBackgroundColor);
}

.emptyDateModal img {
	transition: opacity .1s ease-in-out;
}

.emptyDateModal img:hover {
	opacity: 0.5;
}

.emptyDateModal img {
	align-self: center;
}
.emptyDate {
	display: flex;
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;

}

.emptyDate img {
	transition: opacity .1s ease-in-out;
}

.emptyDate img:hover {
	opacity: 0.5;
}

.emptyDate img {
	align-self: center;
}

.emptyDateDisable {
	cursor: default;
	display: flex;
	font-family: var(--componentFontFamily);
	font-weight: 300;
	font-size: 14px;
}

.emptyDateDisable img {
	transition: opacity .1s ease-in-out;
}

.emptyDateDisable img {
	align-self: center;
}

.calendar.disabled {
	cursor: default;
}

.calendar:disabled::-webkit-calendar-picker-indicator {
	visibility: visible;
}

.calendar.dataChanged {
	border: none;
}

.calendar.dataChanged::-webkit-calendar-picker-indicator {
	/* parasite --> */
	padding-right: 5.5em;
	position: inherit;
	z-index: 1;
	/* parasite <-- */
}

.calendar.dataChanged::-webkit-datetime-edit {
	display: initial;
}

/************************************/

.container {
	display: inline-block;
	position: relative;
	white-space: pre;
}

.container.modalCalendar {
	width: 100%;
	height: 100%;
}

.calendar.showImmutableDate::-webkit-datetime-edit-fields-wrapper {
	display: none;
}

.container .immutableDate {
	position: absolute;
	font-size: 0.93em;
	top: 0.24em;
	right: 0.29em;
	font-weight: 500;
	font-family: sans-serif;
	letter-spacing: -0.038em;
	text-rendering: auto;
	pointer-events: none;
}

.addGap {
	/*gap: 5px;*/
	height: 100%;
}

.addGap > p {
	margin-left: 5px;
	padding-left: 2px;
}


@media screen and (max-width: 1170px) {
	.date {
		font-size: 13px;
	}

	.emptyDate {
		font-size: 13px;
	}
}
