.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.6);
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.container {
    width: 300px;
    height: 100%;
    background-color: var(--darkBackgroundColor);
     overflow-y: hidden; 

}

.logoContainer {
    padding: 28px 36px 45px;
    user-select: none;
}

.logoContainer .LogoSvg {
    filter: var(--filterConverterForDarkSVGtoWhite);
}

.searchBarContainer {
    position: relative;
    height: 2.5em;
    padding: 0 36px;
    margin-bottom: 39px;
}

.searchBarContainer .searchBar {
    position: relative;
}

.navigationTabsContainer {
    position: relative;
    z-index: 2;
}

.addNewWorkspaceButton {
    position: absolute;
    font-size: 20px;
    bottom: 21px;
    right: 36px;
    vertical-align: middle;
    text-align: center;
    line-height: 0.55;
    height: 1em;
    width: 1em;
    font-weight: 600;
    color: var(--inputGray);
    cursor: pointer;
    padding: 1px 1px 0 1px;
}

.addNewWorkspaceButton img:hover {
    filter: var(--filterConverterForGraySVGtoWhite);
}

.workspacesListContainer {
    margin-top: 8px;
    position: relative;
    height: calc(100vh - 580px);
    /* height: calc(100vh - 580px); */
    overflow-y: auto; 
    overflow-x: hidden;
    font-size: 20px;
    padding: 0 12px 0 34px;
    background-color: var(--darkBackgroundColor);
    z-index: 1;
}


/*.workspacesListContainer.hide {*/
/*	opacity: 0;*/
/*	transition: opacity .3s ease-in-out;*/
/*}*/

/*.workspacesListContainer.show {*/
/*	opacity: 1;*/
/*	transition: opacity .3s ease-in-out;*/
/*}*/


.animatedSelectedWorkspaceEnterActive {
    opacity: 0;
    transform: translate(-100%, 0);
}

.animatedSelectedWorkspaceEnterDone {
    opacity: 1;
    transition: opacity, transform 150ms ease-in;
    transform: translate(0, 0);
}

.animatedSelectedWorkspaceExit {
    opacity: 1;
    transform: translate(0, 0);
}

.animatedSelectedWorkspaceExitActive {
    opacity: 0.7;
    transform: translate(100%, 0);
    transition: opacity, transform 150ms ease-in;
}

.animatedSelectedWorkspaceExitDone {
    opacity: 0;
    transform: translate(100%, 0);
}

.workspacesListContainer::-webkit-scrollbar {
    background-color: #ffffff00;
}

.workspacesListContainer::-webkit-scrollbar:vertical {
    width: 8px;
}

.workspacesListContainer::-webkit-scrollbar-thumb {
    background-color: var(--inputGray);
    border-radius: 999em;
}

.workspacesListContainer a {
    text-decoration: none;
}

.workspacesListContainer .workspaceListElement {
    display: flex;
    align-items: center;
    color: var(--inputGray);
    cursor: pointer;
    margin-bottom: 20px;
    user-select: none;
}

.workspacesListContainer .workspaceListElement.current {
    color: var(--componentBackgroundColor);
}

.workspacesListContainer .workspaceListElement:hover {
    color: var(--componentBackgroundColor);
}

.workspacesListContainer .workspaceListElement .workspaceMarker {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: var(--extremelySmallBorderRadius);
}

.hiddenElement {
    font-size: 20px;
    position: absolute;
    visibility: hidden;
}

.workspaceElement {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    color: var(--inputGray);
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.workspaceElement.current {
    color: var(--componentBackgroundColor);
}

.workspaceElement:hover {
    color: var(--componentBackgroundColor);
    cursor: pointer;
}

.workspaceElement:focus {
    border: none;
    outline: none;
    cursor: pointer;
}

.menuToggle {
    display: none;
}

.spinner {
  margin-top: 30px;
}

.clickable {

}

@media screen and (max-width: 1600px) {
    .container {
        width: 250px;
        height: 100vh;
        background-color: var(--darkBackgroundColor);
        position: absolute;
        z-index: 4;
        left: -100%;
        transition: left 300ms ease-in-out;
    }

    .menuToggle {
        opacity: 0;
        z-index: 3;
    }

    .menuToggle:checked ~ .menuButton > span {
        transform: rotate(45deg);
    }

    .menuToggle:checked ~ .menuButton > span::before {
        top: 0;
        width: 100%;
        height: 5px;
        transform: rotate(0);
        background-color: white;
    }

    .menuToggle:checked ~ .menuButton > span::after {
        top: 0;
        transform: rotate(90deg);
        width: 100%;
        height: 5px;
        background-color: white;
    }

    .menuButton {
        display: flex;
        align-items: center;
        position: fixed;
        top: 35px;
        left: 20px;

        width: 26px;
        height: 26px;

        cursor: pointer;
        z-index: 4;
    }

    .menuButton > span,
    .menuButton > span::before,
    .menuButton > span::after {
        display: block;
        position: absolute;

        width: 100%;
        height: 2px;

        background-color: #616161;

        transition-duration: .25s;
    }

    .menuButton > span::before {
        content: '';
        top: -8px;
    }

    .menuButton > span::after {
        content: '';
        top: 8px;
    }

    .hamburger .menuToggle {
        display: block;
        position: absolute;
        z-index: 3;
    }

    .hamburger .menuToggle:checked ~ .container {
        left: 0;
        /*z-index: 1001;*/
    }

    .hamburger .menuToggle:checked ~ .wrapper {
        opacity: 1;
        pointer-events: visible;
    }

    .hamburger .menuToggle + label {
        /*z-index: 1001;*/
        transition: left 300ms ease-in-out;
    }

    .hamburger .menuToggle:checked + label {
        left: 270px;
        transition: left 300ms ease-in-out;
    }

    .workspaceElement {
        max-width: 130px !important;
    }
    .workspacesListContainer {
        max-height: calc(100vh - 450px);
    }
    @media not all and (min-resolution:.001dpcm){ @media screen and (max-width: 800px){
        .workspacesListContainer {
            max-height: calc(100vh - 550px);
        }
    }}

}

@media screen and (max-width: 720px) {
    .container {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .workspacesListContainer {
      height: 100%;
      max-height: 100%;
      overflow: unset;
    }
    .workspacesListContainer > div:last-child {
      padding-bottom: 45px;
    }
}
@media screen and (max-height: 700px) {
    .container {
        overflow-y: auto;
        overflow-x: hidden;
      }
      .workspacesListContainer {
        height: 100%;
        max-height: 100%;
        overflow: unset;
      }
}