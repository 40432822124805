@font-face {
    font-family: 'Raleway';
    src: url('./Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Raleway Bold';
    src: url('./Raleway/static/Raleway-Bold.ttf');
}

@font-face {
    font-family: 'Raleway Medium';
    src: url('./Raleway/static/Raleway-Medium.ttf')
}

@font-face {
    font-family: 'Raleway-italic';
    src: url('./Raleway/Raleway-Italic-VariableFont_wght.ttf');
}
