.container {
	position: relative;
	max-height: calc(100% + 1em);
	transition: max-height var(--superFastAnimationDuration) ease-in;
	overflow: hidden;
}

.searchInputLineContainer {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 2.5em;
	padding: 0 0.625em;
	border: 1px solid var(--borderDark);
	border-radius: var(--defaultBorderRadius);
	background-color: var(--darkBackgroundHoverColor);
	cursor: text;
}

.searchInputLineContainer::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loupeSVG {
	height: 100%;
	width: 1em;
	filter: var(--filterConverterForDarkSVGtoGray);
	user-select: none;
}

.inputField {
	position: relative;
	width: calc(100% - 25px);
	height: 100%;
	z-index: 2;
	color: #A1A1A1;
	border: none;
	background-color: #ffffff00;
}

.inputField input,
.inputField input::placeholder {
	color: var(--inputGray);
}

.inputField:focus {
	outline: none;
}

.container.isOpen {
	top: -0.5em;
	margin: 0 -0.5em -0.5em -0.5em;
	max-height: calc(50vh + 1em);
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	z-index: 3;
}

.container.isOpen .inputField input,
.container.isOpen .inputField input::placeholder {
	color: var(--borderDark);
}

.container.isOpen .loupeSVG {
	filter: none;
}

.searchInputLineContainer.isOpen {
	margin: 0.5em;
	background-color: var(--componentBackgroundColor);
}

.searchResult {
	max-height: calc(50vh + 1em);
	overflow-y: overlay;
	overflow-x: hidden;
	/*padding: 0.8333em 0 0 0;*/
}

.searchResult .searchResultGroupName {
	font-size: 12px;
	text-transform: uppercase;
	font-family: var(--componentFontFamily);
	font-weight: 500;
	color: var(--accentGray);
	margin-bottom: 1.1667em;
	margin: 0.5em;
}

.searchResult a {
	text-decoration: none;
}

.searchResult .searchResultListElement {
	font-size: 16px;
	line-height: 2;
	font-family: var(--headerFontFamily);
	font-weight: 600;
	color: var(--inputGray);
	transition: color .08s ease-in;
	padding: 0.2em 0 0.2em 0.5em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.selectedWorkspace {
	font-size: 16px;
	line-height: 2;
	font-family: var(--headerFontFamily);
	background: var(--backgroundDarkGray);
	font-weight: 600;
	padding: 0.2em 0 0.2em 0.5em;
	color: var(--inputGray);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.searchResult .searchResultListElement:hover {
	font-size: 16px;
	line-height: 2;
	font-family: var(--headerFontFamily);
	background: var(--backgroundDarkGray);
	font-weight: 600;
	color: var(--inputGray);
}

.searchHelper {
	margin-top: 1em;
	text-align: center;
	max-height: 5.875em;
}

.searchHelper > .svgIllustration {
	width: 3em;
	margin-bottom: 0.5em;
}

.searchHelper > p {
	color: var(--inputGray);
	margin-bottom: 0.5em;
}
