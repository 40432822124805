.document {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	height: 55px;
	padding: 9px 15px;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 1px solid var(--lightGrayBorder);
	border-radius: var(--defaultBorderRadius);
	animation: show .5s ease-in;
}

.document object {
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	width: 32px;
	margin-right: 3px;
	filter: var(--filterConverterForDarkSVGtoGray);
}

.document .documentIcon {
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	width: 32px;
	margin-right: 3px;
	cursor: pointer;
}

.document .documentIcon .documentIconBody {
	fill: var(--inputGray);
}

.document .deleteDocumentFromTaskButton {
	position: absolute;
	text-align: center;
	top: -0.45em;
	right: -0.45em;
	width: 0.9em;
	height: 0.9em;
	border: 0;
	border-radius: 9999em;
	opacity: 0;
	transform: rotate(45deg);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	user-select: none;
	cursor: pointer;
	transition: opacity var(--fastAnimationDuration) ease-in;
	outline: none;
	z-index: 2;
}

.document .deleteDocumentFromTaskButton::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 9999em;
}

.document .deleteDocumentFromTaskButton .closeIcon {
	position: relative;
	bottom: 0.2em;
	left: 0;
	width: 65%;
}

.document .deleteDocumentFromTaskButton .closeIcon path {
	fill: var(--declineColor);
}

.document:hover .deleteDocumentFromTaskButton {
	opacity: 1;
}

.document .deleteDocumentFromTaskButton:hover {
	opacity: 1;
}

.document .imageInfo {
	display: inline-block;
	max-width: 124px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 10px;
}

.document .imageInfo p {
	display: inline;
	position: relative;
	top: 3px;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.document .imageInfo progress {
	position: relative;
	bottom: 3px;
	width: 100%;
	height: 4px;
	appearance: none;
}

.document .imageInfo progress::-webkit-progress-bar {
	border-radius: 9999em;
	background-color: var(--accentGray);
}

.document .imageInfo progress::-webkit-progress-value {
	border-radius: 9999em;
}

.document .documentInfo {
	display: inline-block;
	vertical-align: middle;
	max-width: 124px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.document .documentInfo p {
	display: inline;
	position: relative;
	top: 3px;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.document .documentInfo progress {
	position: relative;
	bottom: 3px;
	width: 100%;
	height: 4px;
	appearance: none;
}

.document .documentInfo progress::-webkit-progress-bar {
	border-radius: 9999em;
	background-color: var(--accentGray);
}

.document .documentInfo progress::-webkit-progress-value {
	border-radius: 9999em;
}

.imagePreview {
	max-width: 80px;
	cursor: pointer;
	position: relative;
	object-fit: cover;
	height: 100%;
	background-color: inherit;
	user-select: none;
}

@keyframes show {
	0%{
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
