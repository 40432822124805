.wrapper {
	position: relative;
}

.inputLabel {
	position: relative;
	font-size: 0.8125em;
	line-height: 1.8;
	font-family: var(--componentFontFamily);
	font-weight: 600;
	color: var(--labelGray);
	transition: color 0.2s ease-in-out;
}

.inputLabelError{
	position: relative;
	font-size: 0.8125em;
	line-height: 1.8;
	font-family: var(--componentFontFamily);
	font-weight: 600;
	color: #e74c3c;
	transition: color 0.2s ease-in-out;
}

.defaultInput {
	width: 100%;
	font-size: 1.25em;
	line-height: 2;
	padding: 0 0.8em;
	font-family: var(--componentFontFamily);
	font-weight: 600;
	border: 1px solid var(--accentGray);
	border-radius: var(--defaultBorderRadius);
	color: var(--darkBackgroundColor);
	outline: none;
	transition: border 0.2s ease-in-out;
}

.defaultInput:focus {
	border: 1px solid var(--inputGray);
}

.defaultInputError{
	width: 100%;
	font-size: 1.25em;
	line-height: 2;
	padding: 0 0.8em;
	font-family: var(--componentFontFamily);
	font-weight: 600;
	border: 1px solid #e74c3c;
	border-radius: var(--defaultBorderRadius);
	color: var(--darkBackgroundColor);
	outline: none;
	transition: border 0.2s ease-in-out;
}

.passwordIcon {
	position: absolute;
	right: 10px;
	bottom: 10px;
	left: auto;
	cursor: pointer;
}
