.body {
    height: 23px;
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    width: 120px;
}

.container {
    height: 23px;
    /*background-color: #FFF3FF;*/
    border-radius: 4px;
    transition: background-color var(--superFastAnimationDuration) ease-in-out;
}

.container:hover {

}

.name {
    white-space: nowrap;
    color: var(--standartBlackColor);
    line-height: 23px;
    margin: 0 10px 0 10px;
    text-align: center;
    font-size: 13px;
    font-family: var(--componentFontFamily);
}

.options {
    position: relative;
    z-index: 8;
    height: 23px;
    /*background-color: #FFF3FF;*/
    border-radius: 4px;
}

.sourcesList {
    position: relative;
    z-index: 2;
    background-color: #FFF;
    border-radius: 4px;
}
