.searchBar {
	background-color: var(--darkGreyBackground);
	border: 1px solid #ffffff00;
	color: var(--inputDark);
	transition: background-color var(--superFastAnimationDuration) ease-in,
		color var(--superFastAnimationDuration) ease-in,
		border var(--superFastAnimationDuration) ease-in;
}

.searchBar > object {
	filter: var(--filterConverterForDarkSVGtoDarkGray);
	transition: filter var(--superFastAnimationDuration) ease-in;
}

.searchBar::placeholder {
	color: var(--inputDark);
	transition: color var(--superFastAnimationDuration) ease-in;
}

.searchBar.focus {
	background-color: var(--componentBackgroundColor);
	color: var(--darkTextColor);
	border: 1px solid var(--accentGray);
}

.searchBar.focus > object {
	filter: var(--filterConverterForDarkSVGtoGray);
}

.searchBar.focus input::placeholder {
	color: var(--darkTextColor);
}
