.header {
    height: 100px;
    width: 100%;
    box-shadow: var(--headerBoxShadow);
    display: flex;
    padding: 0 32px 0 32px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: relative;
    z-index: 2;
}

.rightSide {
    display: flex;
    align-items: center;
}

.rightSide div:not(:last-child) {
    /*margin-right: 20px;*/
}

.exitButton {
    height: 35px;
    padding: 5px 15px;
}

@media screen and (max-width: 1085px){
    .rightSide p:nth-child(2){
        display: none;
    }
}
