/*gotta do smthg with these damn animations*/

.wrapper {
	padding-left: 300px;
}


.locked {
	position: fixed;
	width: 100%;
	height: 100%;
}

.lockedWindow {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background-color: var(--componentBackgroundColor);
}

.lockedBody {
	position: absolute;
	top: 15%;
	left: 30%;
}

.opened > div {
	opacity: 1;
	transform: scale(1);
	transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}

.isClosing div {
	opacity: 0;
	transform: scale(0.1);
	transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}

.lockedBody h1 {
	font-size: 40px;
	color: var(--accentGray);
	margin-top: 50px;
	text-align: center;
}

.lockIcon {
	width: 500px;
	height: 500px;
	filter: var(--filterConverterForDarkSVGtoGray);
	opacity: 0.4;
}

.declineComment {
	font-size: 15px;
	width: 100%;
	padding: 13px;
	line-height: 1.2666;
	font-family: var(--headerFontFamily);
	border: 1px solid var(--lightGrayBorder);
	border-radius: var(--defaultBorderRadius);
	resize: none;
	outline: none;
	min-height: 100px;
	max-height: 300px;
	overflow-y: scroll;
	transition: border var(--superFastAnimationDuration) ease-in-out;
}

.declineComment:focus {
	border: 1px solid var(--accentGray);
}

.wrapper.modalShowing {
	opacity: 0;
	transform: scale(0.8);
	transition: transform var(--superFastAnimationDuration) ease-in,
	filter var(--superFastAnimationDuration)  ease-in;
}

.modalShowing > * {
	transform: scale(0.8);
	transition: transform var(--superFastAnimationDuration)  ease-in,
	filter var(--superFastAnimationDuration)  ease-in;
}

.wrapper.modalShow {
	transform: scale(1);
	display: flex !important;
	opacity: 1;
}


.wrapper.modalClosing {
	display: flex !important;
	opacity: 0;
	transition: opacity var(--fastAnimationDuration) ease-in;
}

.modalClosing > * {
	transform: scale(0.8);
	transition: transform var(--fastAnimationDuration) ease-in,
	filter var(--fastAnimationDuration) ease-in;
}

.wrapper.modalClose {
	display: none !important;
	opacity: 0;
}


.wrapper.hide {
	display: none !important;
	animation: show var(--fastAnimationDuration) ease-in;
}

@keyframes show {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: flex;
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.wrapper {
	display: flex;
	position: fixed;
	font-family: var(--headerFontFamily);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-color: var(--modalWindowBackground);
	opacity: 1;
	z-index: 999;
	transition: opacity var(--slowAnimationDuration) ease-in;
}

.closeButton {
	position: absolute;
	line-height: 0.5;
	font-size: 2em;
	top: 0.85em;
	right: 0.8em;
	cursor: pointer;
}

.modalWindowDefaultSize {
	position: relative;
	min-width: 25%;
	max-width: 50%;
	max-height: 80vh;
}

.modalDefaultHeader {
	position: relative;
	height: 3.437em;
	padding: 1.5em 1.375em 0.3125em 1.375em;
	font-family: var(--componentFontFamily);
	cursor: default;
}

/* NOTIFICATION */

.notificationWindow {
	border-radius: var(--defaultBorderRadius);
	overflow: hidden;
	background-color: var(--componentBackgroundColor);
	color: var(--darkBackgroundColor);
	animation: show var(--fastAnimationDuration) ease-in;
}

.notificationWindow .header h2 {
	font-size: 1.5em;
	line-height: 1;
	font-weight: bold;
}

.notificationWindow .body {
	padding: 1em 1.375em;
	font-family: var(--componentFontFamily);
}

.notificationWindow .footer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 1.625em 1.438em;
}

.notificationWindow .footer .okButton {
	padding: 0 2.5em;
	line-height: 2;
	font-weight: 500;
}

.notificationWindow .footer .cancelButton {
	padding: 0 2.5em;
	line-height: 2;
	font-weight: 500;
	margin-left: 20px;
	background: var(--accentGray);
}

/* TRIPLE WINDOW TASK */

.tripleWindowTask > div {
	height: 665px;
}

.tripleWindowTask .card {
	height: 100%;
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--modalWindowBoxShadow);
}


.tripleWindowTask .windowLabel {
	margin-bottom: 12px;
	font-family: var(--headerFontFamily);
	color: var(--lightGrayBorder);
	user-select: none;
	opacity: 0;
	transition: opacity var(--slowAnimationDuration) ease-in-out;
}

.tripleWindowTask .rightSideWindow,
.tripleWindowTask .leftSideWindow {
	position: relative;
	width: 382px;
	z-index: 1;
	max-height: 665px;
	overflow: hidden;
}

.tripleWindowTask .windowLabel.show {
	opacity: 1;
}

.tripleWindowTask .rightSideWindow .card,
.tripleWindowTask .leftSideWindow .card {
	background-color: var(--lightGrayBorder);
	font-family: var(--componentFontFamily);
}

.tripleWindowTask .leftSideWindow.show {
	left: 0;
	opacity: 1;
	transition: opacity var(--slowAnimationDuration) ease-in-out, left var(--slowAnimationDuration) ease-in-out;
}

.tripleWindowTask .leftSideWindow {
	left: 392px;
	opacity: 0;
	transition: opacity var(--slowAnimationDuration) ease-in-out, left var(--slowAnimationDuration) ease-in-out;
}

.tripleWindowTask .leftSideWindow .card h3 {
	font-size: 17px;
	padding: 28px 17px 0 17px;
	font-weight: 700;
	color: var(--darkBackgroundColor);
	margin-bottom: 24px;
	/*height: 130px;*/
	overflow: hidden;
}

.tripleWindowTask .leftSideWindow .card .parentTaskInformationBar {
	position: relative;
	height: 22px;
	margin-bottom: 21px;
}

.leftSideWindow .card .parentTaskInformationBar .leftSide,
.leftSideWindow .card .parentTaskInformationBar .rightSide {
	position: absolute;
	height: 22px;
	top: 0;
}

.leftSideWindow .card .parentTaskInformationBar .leftSide {
	left: 17px;
}

.leftSideWindow .card .parentTaskInformationBar .rightSide {
	right: 17px;
}

.tripleWindowTask
.leftSideWindow
.card
.parentTaskInformationBar
.prioritySelector {
	font-size: 13px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.tripleWindowTask
.leftSideWindow
.card
.parentTaskInformationBar
.themeSelector,
.tripleWindowTask .leftSideWindow .card .parentTaskInformationBar .calendar {
	font-size: 13px;
	display: inline-block;
	vertical-align: middle;
}

.leftSideWindow .card .parentTaskDescription {
	padding: 0 17px;
	min-height: 435px;
	max-height: 435px;
	overflow-y: scroll;
}

.leftSideWindow .card .footer {
	height: 90px;
	padding: 23px 20px 0 16px;
}

.leftSideWindow .card .footer .peoplesContainer {
	font-size: 16px;
	display: inline-block;
	vertical-align: middle;
}

.leftSideWindow .card .footer .openParentTaskButton {
	display: inline-block;
	position: absolute;
	right: 20px;
	font-size: 15px;
	vertical-align: middle;
	padding: 7px 21px;
	font-family: var(--headerFontFamily);
	color: var(--darkBackgroundColor);
	background-color: var(--componentBackgroundColor);
	border: 0;
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	outline: none;
	cursor: pointer;
}

.tripleWindowTask .centralWindow {
	position: relative;
	width: 671px;
	margin: 0 10px;
	z-index: 2;
	height: auto;
	animation: show var(--fastAnimationDuration) ease-in forwards;
}

.tripleWindowTask .centralWindow .card {
	background-color: var(--componentBackgroundColor);
	overflow: hidden;
	min-width: 650px;
	overflow: visible;
}

.centralWindow .card .cardHeader {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	height: 73px;
	padding: 19px 22px;
	box-shadow: var(--modalWindowBoxShadow);
}

.centralWindow .card .cardHeader .doneButton {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	height: 35px;
	padding: 7px 11px;
	margin-right: 10px;
	font-family: var(--headerFontFamily);
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	color: var(--labelGray);
	cursor: pointer;
	transition: background-color var(--superFastAnimationDuration) ease-in-out;
}

.centralWindow .card .cardHeader .doneButton:hover {
	background-color: var(--lightGrayBorder);
}

.centralWindow .card .cardHeader .doneButton.disabled {
	/*filter: var(--filterConverterForDarkSVGtoGray);*/
	opacity: 0.5;
	cursor: default;
}

.notAllowedCursor {
	cursor: default;
}

.centralWindow .card .cardHeader .doneButton::after,
.centralWindow .card .cardHeader .headerButton::after,
.centralWindow .card .cardHeader .optionsButton::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.optionsButton:hover {
	box-shadow: var(--modalWindowBoxShadow);
}

.cardHeader .doneButton .objectContainer {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	height: 21px;
	width: 21px;
	margin-right: 8px;
}

.cardHeader .doneButton .objectContainer.done::after,
.cardHeader .doneButton .objectContainer.verification::after {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;
	height: 1px;
	height: calc(100% - 2px);
	width: calc(100% - 2px);
	border-radius: 9999em;
	z-index: 1;
}

.cardHeader .doneButton .objectContainer.done::after {
	background-color: var(--greenDone);
}

.cardHeader .doneButton .objectContainer.verification::after {
	background-color: var(--yellowVerification);
}

.cardHeader .doneButton .objectContainer.done object,
.cardHeader .doneButton .objectContainer.verification object {
	position: relative;
	filter: var(--filterConverterForDarkSVGtoWhite);
	z-index: 2;
}

.cardHeader .doneButton .objectContainer object {
	height: 100%;
	width: 100%;
	filter: var(--filterConverterForDarkSVGtoDarkGray);
}

.cardHeader .doneButton span {
	vertical-align: middle;
	font-weight: 600;
}

.cardHeader .verificationButtonBar {
	display: inline-block;
	height: 32px;
	padding: 0 10px 0 0;
}

.cardHeader .verificationButtonBar > * {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	text-align: center;
	height: 32px;
	border: 0;
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	user-select: none;
	cursor: pointer;
	outline: none;
}

.cardHeader .verificationButtonBar > *::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.cardHeader .verificationButtonBar > *:first-child {
	border-radius: var(--defaultBorderRadius) 0 0 var(--defaultBorderRadius);
}

.cardHeader .verificationButtonBar > *:last-child {
	border-radius: 0 var(--defaultBorderRadius) var(--defaultBorderRadius) 0;
}

.cardHeader .verificationButtonBar > *:not(:last-child) {
	margin-right: 2px;
}

.cardHeader .verificationButtonBar .acceptButton,
.cardHeader .verificationButtonBar .declineButton {
	width: 40px;
}

.acceptButton {
	transition: background-color var(--superFastAnimationDuration) ease-in-out;
}

.declineButton {
	transition: background-color var(--superFastAnimationDuration) ease-in-out;
}

.acceptButton:hover {
	background-color: var(--lightGrayBorder);
}

.declineButton:hover {
	background-color: var(--lightGrayBorder);
}

.cardHeader .verificationButtonBar > * > object {
	vertical-align: middle;
	filter: var(--filterConverterForDarkSVGtoGray);
}

.cardHeader .verificationButtonBar .acceptButton object {
	width: 13px;
}

.cardHeader .verificationButtonBar .declineButton object {
	width: 13.32px;
	transform: rotate(45deg);
}

.cardHeader .verificationButtonBar .acceptButton.active {
	background-color: var(--greenDone);
}

.cardHeader .verificationButtonBar .declineButton.active {
	background-color: var(--declineColor);
}

.cardHeader .verificationButtonBar .acceptButton.active object,
.cardHeader .verificationButtonBar .declineButton.active object {
	filter: var(--filterConverterForDarkSVGtoWhite);
}

.cardHeader .headerButton {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 35px;
	height: 35px;
	padding: 7px;
	border: 0;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	cursor: pointer;
	outline: none;
}

.cardHeader .headerButton object {
	filter: var(--filterConverterForDarkSVGtoDarkGray);
}

.cardHeader .headerButton.disabled object {
	filter: var(--filterConverterForDarkSVGtoGray);
}

.cardHeader .headerButton:hover {
	box-shadow: var(--modalWindowBoxShadow);
}

.cardHeader .rightSideButtonsGroup {
	user-select: none;
}

.cardHeader .rightSideButtonsGroup .headerButton {
	margin-right: 13px;
	/* transition: box-shadow var(--fastAnimationDuration) ease-in,
		filter var(--fastAnimationDuration) ease-in; */
}

.cardHeader .rightSideButtonsGroup .headerButton.show {
	box-shadow: var(--modalWindowBoxShadow);
}

.cardHeader .rightSideButtonsGroup .headerButton.show object {
	filter: var(--filterConverterForDarkSVGtoRed);
}

.cardHeader .optionsButton {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 35px;
	height: 35px;
	padding: 7px;
	cursor: pointer;
}

.cardHeader .optionsButton:not(:last-child) {
	margin-right: 13px;
}

.cardHeader .optionsButton object {
	vertical-align: middle;
	filter: var(--filterConverterForDarkSVGtoDarkGray);
}

.cardHeader .sectionOptionsMenu {
	padding: 8px 1em;
}

.cardHeader .sectionOptionsMenu:hover {
	background-color: var(--backgroundGray);
}

.cardHeader .optionsButton.closeButton {
	top: 0;
	right: 0;
	font-size: 1em;
	line-height: 1;
}

.cardHeader .optionsButton.closeButton object {
	transform: rotate(45deg);
}

.centralWindow .card .cardBody {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 600px;
	padding: 30px 22px;
	font-family: var(--componentFontFamily);
}

.centralWindow .card .cardBody h3,
.centralWindow .card .cardBody .taskName {
	/*height: 41px;*/
	max-height: 65px;
	resize: none;
	max-width: 640px;
	font-size: 20px;
	width: 100%;
	padding: 0.35em 0.5em 0.35em 0;
	margin-bottom: 35px;
	font-family: var(--componentFontFamily);
	font-weight: 700;
	color: var(--darkBackgroundColor);
	border: 1.5px solid #ffffff00;
	border-radius: var(--smallBorderRadius);
	outline: none;
	transition: border var(--superFastAnimationDuration) ease-in,
	padding var(--superFastAnimationDuration) ease-in;
}

.centralWindow .card .cardBody .taskName.isEdit {
	padding: 0.35em 0.25em 0.35em 0.25em;
	border: 1.5px solid var(--inputGray);
}

.centralWindow .card .cardBody h3,
.centralWindow .card .cardBody .taskNameError {
	max-width: 640px;
	font-size: 20px;
	width: 100%;
	padding: 0.35em 0.5em 0.35em 0;
	margin-bottom: 35px;
	font-family: var(--componentFontFamily);
	font-weight: 700;
	color: var(--darkBackgroundColor);
	border: 1.5px solid red;
	border-radius: var(--smallBorderRadius);
	outline: none;
	transition: border var(--superFastAnimationDuration) ease-in,
	padding var(--superFastAnimationDuration) ease-in;
}

.centralWindow .card .cardBody .taskNameError.isEdit {
	padding: 0.35em 0.25em 0.35em 0.25em;
	border: 1.5px solid var(--inputGray);
}

.centralWindow .card .cardBody h3,
.centralWindow .card .cardBody .taskNameError:before {
	content: 'test';
}

.centralWindow .card .cardBody .taskForm {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.card .cardBody .taskForm {
	margin-bottom: 19px;
}

.taskForm .formElement {
	width: 295px;
	height: 32px;
	margin-bottom: 13px;
}

.formElement :not(.label) {
	font-family: var(--componentFontFamily);
	font-weight: 400;
}

.centralWindow .card .cardBody .label {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	font-size: 15px;
	width: 115px;
	margin-right: 6px;
	font-family: var(--headerFontFamily);
	color: var(--inputGray);
}

.centralWindow .card .cardBody .inputSize {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	width: 167px;
	height: 32px;
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	cursor: pointer;
	top: 0;
}

.sectionName {
	font-size: 16px !important;
	font-weight: 400 !important;
	font-family: var(--componentFontFamily) !important;
	overflow: hidden;
	white-space: pre;
	/* width: 90%; */
	text-overflow: ellipsis;
}

.centralWindow .card .cardBody .inputSize.deadline {
	background: var(--deadlineColor);
}

.taskForm .formElement .userInput {
	position: relative;
	vertical-align: middle;
	/*font-size: 14px;*/
	font-weight: 500;
	color: var(--darkBackgroundColor);
	cursor: pointer;
	transition: background-color .1s ease-in;
}

.taskForm .formElement .userInput > p {
	font-size: 14px;
	font-family: var(--headerFontFamily);
}

.taskForm .formElement .userInput > h1 {
	font-size: 14px;
	font-family: var(--headerFontFamily);
	font-weight: 600;
}

.taskForm .formElement .userInput:hover {
	background-color: #f7f7f7;
}

.taskForm .formElement .notEditable:hover {
	background-color: var(--componentBackgroundColor);
	cursor: default !important;
}

.taskForm .formElement .userInput {
	top: 0.6em;
	width: 260px;
}

.roleUserChanger{
	top: 3em;
	width: 260px;
	z-index: 3;
}

.taskForm .formElement .avatar {
	vertical-align: middle;
	height: 28px;
	width: 28px;
	margin-right: 6px;
}

.taskForm .formElement .userInput.notChanged {
	vertical-align: middle;
	/*font-size: 14px;*/
	font-family: var(--headerFontFamily);
	font-weight: 500;
	color: var(--labelGray);
	cursor: pointer;
}

.taskForm .formElement .deleteButtonOnTaskForm {
	position: absolute;
	text-align: center;
	top: -9px;
	right: -9px;
	width: 17px;
	height: 17px;
	border: 0;
	border-radius: 9999em;
	opacity: 0;
	transform: rotate(45deg);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	user-select: none;
	cursor: pointer;
	transition: opacity var(--fastAnimationDuration) ease-in;
	outline: none;
}

.taskForm .formElement .deleteButtonOnTaskForm::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 9999em;
}

.taskForm .formElement .deleteButtonOnTaskForm object {
	width: 65%;
	vertical-align: middle;
	filter: var(--filterConverterForDarkSVGtoRed);
}

.taskForm .formElement .userInput:hover .deleteButtonOnTaskForm,
.taskForm .formElement .dateInput:hover .deleteButtonOnTaskForm {
	opacity: 1;
}

.taskForm .formElement .dateInput {
	position: relative;
}

.taskForm .formElement .dateInput .calendar {
	height: 100%;
	width: 100%;
	padding: 0 8px;
	font-family: var(--componentFontFamily);
	font-weight: 700;
	border: 0;
}

.taskForm .formElement .dateInput .calendar::-webkit-calendar-picker-indicator {
	padding-right: 9.5em;
}

.taskForm .formElement .prioritySelector {
	width: 100%;
	height: 100%;
}

.taskForm .formElement .priorityButton {
	width: 100%;
	height: 32px;
}

.taskForm .formElement .priorityList {
	width: 100%;
}

.dropdownButton {
	width: 100%;
	padding: 0.5em 1.4em 0.5em 0.625em !important;
	text-align: left;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	box-shadow: none !important;
}

.dropdownDisabled {
	color: grey;
	opacity: 0.5;
	cursor: default !important;
}

.dropdownDisabled:hover {
	background-color: var(--componentBackgroundColor) !important;
}

.dropdownButton svg {
	position: absolute;
	top: 13.43px;
	right: 10px;
}

button.dropdownButtonIsOpen.dropdownButton {
	position: relative;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 4;
	box-shadow: none;
}

.taskForm .formElement .dropdownButtonOptionList {
	position: absolute;
	top: 0;
	width: 100%;
	padding-top: calc(2.4em + 1px);
	font-family: var(--componentFontFamily);
	font-size: 0.8125em;
	font-weight: 500;
	border-radius: var(--defaultBorderRadius);
	overflow: hidden;
	background-color: var(--componentBackgroundColor);
	z-index: 3;
	box-shadow: var(--defaultBoxShadow);
	height: 200px;
	overflow-y: scroll;
}

.taskForm .formElement .dropdownButtonOptionListElement {
	padding: 0.6923em 0.7692em;
	border-top: 1px solid var(--lightGrayBorder);
	cursor: pointer;
}

.taskForm .formElement .dropdownButtonOptionListElement:hover {
	background-color: var(--lightGrayBorder);
}

.card .cardBody .taskDescription {
	min-height: 120px;
	white-space: pre-wrap;
	overflow-y: hidden;
	overflow-x: hidden;
	font-size: 16px;
	line-height: 22px;
	width: 100%;
	padding: 0.35em 0.5em 0.35em 0;
	resize: none;
	margin-bottom: 25px;
	font-family: var(--componentFontFamily);
	color: var(--darkBackgroundColor);
	border: 1.5px solid #ffffff00;
	border-radius: var(--smallBorderRadius);
	outline: none;
	transition: border var(--superFastAnimationDuration) ease-in,
	padding var(--superFastAnimationDuration) ease-in;
}

.card .cardBody .taskDescription[placeholder]:empty:before {
	content: attr(placeholder);
	color: var(--inputGray);
}

.card .cardBody .taskDescription.isEdit {
	padding: 0.35em 0.25em 0.35em 0.25em;
	border: 1.5px solid var(--inputGray);
}

.card .cardBody .taskDescriptionHidden {
	min-height: 120px;
	visibility: hidden;
	white-space: pre-wrap;
	position: absolute;
	overflow-y: hidden;
	overflow-x: hidden;
	font-size: 16px;
	line-height: 22px;
	padding: 0.35em 0.5em 0.35em 0;
	padding-right: 28px;
	resize: none;
	margin-bottom: 25px;
	font-family: var(--componentFontFamily);
	color: var(--darkBackgroundColor);
	border: 1.5px solid #ffffff00;
	border-radius: var(--smallBorderRadius);
	outline: none;
	transition: border var(--superFastAnimationDuration) ease-in,
	padding var(--superFastAnimationDuration) ease-in;
}

.card .cardBody .taskDescriptionHidden.isEdit {
	padding: 0.35em 0.25em 0.35em 0.25em;
	border: 1.5px solid var(--inputGray);
}

.card .cardBody h4 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
}

.card .cardBody .documentsList {
	position: relative;
}

.documentsList .addDocumentButton {
	display: inline-block;
	vertical-align: top;
	font-size: 41px;
	height: 55px;
	width: 55px;
	text-align: center;
	border: 1px solid var(--lightGrayBorder);
	border-radius: var(--defaultBorderRadius);
	color: var(--accentGray);
	user-select: none;
	cursor: pointer;
}

.documentsList input[type='file'] {
	display: none;
}

/*********************************************/

.centralWindow .card .createNewTaskButton {
	position: relative;
	float: right;
	right: 0;
	bottom: 0;
	padding: 0.2em 0.75em;
}

.centralWindow .card .createNewTaskButton:hover {
	background: #B93B5D;
}

.tripleWindowTask .rightSideWindow {
	right: 392px;
	opacity: 0;
	transition: opacity var(--slowAnimationDuration) ease-in-out, right var(--slowAnimationDuration) ease-in-out;
}

.tripleWindowTask .rightSideWindow.show {
	right: 0;
	opacity: 1;
}

.tripleWindowTask .rightSideWindow .card {
	height: 700px;
	padding: 26px 17px;
	overflow-y: auto;
	overflow-x: hidden;
    max-height: 640px;
}

.tripleWindowTask .rightSideWindow .card::-webkit-scrollbar {
	background-color: #ffffff00;
}

.tripleWindowTask .rightSideWindow .card::-webkit-scrollbar:vertical {
	width: 8px;
}

.tripleWindowTask .rightSideWindow .card::-webkit-scrollbar-thumb {
	background-color: var(--accentGray);
	border-radius: 999em;
}

.rightSideWindow .card .logListElement {
	font-family: var(--componentFontFamily);
	margin-bottom: 35px;
}

.rightSideWindow .card .logListElement .dateTime {
	font-size: 13px;
	color: var(--inputGray);
	margin-bottom: 7.35px;
}

.rightSideWindow .card .logListElement .logText {
	font-size: 16px;
	line-height: 1.1875;
	color: var(--labelGray);
}

/* DECLINE TASK WINDOW */

.taskDeclineWindow {
	width: 502px;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	animation: show var(--fastAnimationDuration) ease-in;
}

.taskDeclineWindow .body {
	padding: 28px 26px 28px 22px;
}

.taskDeclineWindow .body textarea {
	font-size: 15px;
	width: 100%;
	padding: 13px;
	line-height: 1.2666;
	font-family: var(--headerFontFamily);
	border: 1px solid var(--lightGrayBorder);
	border-radius: var(--defaultBorderRadius);
	resize: none;
	outline: none;
}

.taskDeclineWindow .body textarea::placeholder {
	color: var(--inputGray);
}

.taskDeclineWindow .footer {
	font-size: 19px;
	padding: 0 26px 28px 22px;
	text-align: right;
}

.taskDeclineWindow .footer .declineButton {
	padding: 0 1em;
	line-height: 2.3em;
	margin-right: 2em;
	border: 0;
	font-family: var(--headerFontFamily);
	font-weight: 650;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	color: var(--darkBackgroundColor);
	outline: none;
	cursor: pointer;
}

.taskDeclineWindow .footer .declineButton:hover {
	background-color: var(--backgroundGray);
}

.taskDeclineWindow .footer .okButton {
	padding: 0 1em;
	line-height: 2.3em;
	font-family: var(--headerFontFamily);
	font-weight: 500;
	outline: none;
}

.taskDeclineWindow .footer .disabledButton {
	padding: 0 1em;
	line-height: 2.3em;
	font-family: var(--headerFontFamily);
	font-weight: 500;
	outline: none;
	background: var(--accentGray);
	cursor: default;
}

.icon{
	filter: var(--filterConverterForDarkSVGtoGray);
}

.calendarContainer div img{
	padding-left: 10px;
}

.currencyButton {

}

.currencyButton button {
	padding: 0;
	height: 30px;
	width: 55px;
	box-shadow: 0 0 0 1px var(--lightGrayBorder);
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.currencyButton button p {
	line-height: 36px;
	height: 100%;
}

.currencyButton button svg {
	margin-left: 0;
	margin-right: 5px;
}

.currencyButtonOptionList {
	/*padding: 0.5em 0.625em;*/
	line-height: 1;
	font-size: 1em;
	font-family: var(--dropdownFontFamily);
	font-variation-settings: var(--fontWeightVariableLight);
	border: none;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	outline: none;
	cursor: pointer;
	transition: background-color .1s ease-in;
	-webkit-user-select: none;
	user-select: none;
	position: absolute;
	top: 30px;
	width: 100%;
}

.currencyButtonOptionListElement {
	padding: 5px 0px 5px 5px;
	border-bottom: 1px solid var(--lightGrayBorder);
}

.currencyButtonOptionListElement:last-child {
	border-radius: 0 0 8px 8px;
}

.currencyButtonOptionListElement:hover {
	background-color: var(--lightGrayBorder);
}

.currencyStyle {
	width: 70px;
}

/*.calendarContainer div p{*/
/*	padding-left: 5px;*/
/*}*/

@media screen and (max-width: 1600px) {
	.wrapper {
		padding-left: 0;
	}
}

.centralWindow .card .cardBody h3,
.centralWindow .card .cardBody .hideElement {
	visibility: hidden;
	position: absolute;
	opacity: 0;
	max-width: 640px;
	font-size: 20px;
	padding: 0.35em 0.5em 0.35em 20px;
	font-family: var(--componentFontFamily);
	font-weight: 700;
	color: var(--darkBackgroundColor);
	border: 1.5px solid #ffffff00;
	border-radius: var(--smallBorderRadius);
	outline: none;
}

.backArrow {
	position: absolute;
	top: 6%;
	left: -7%;
	transform: rotate(180deg);
	height: 30px;
	width: 60px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 10px;
	filter: var(--filterConverterForDarkSVGtoWhite);
}

.textAreaContainer {
	position: relative;
}

.textAreaContainer div {
	position: absolute;
	top: -35px;
	right: 0;
	cursor: pointer;
	padding: 5px;
	color: var(--darkBackgroundColor);
	background-color: var(--componentBackgroundColor);
	border: 0;
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	outline: none;
}

.taskDocs {
	position: relative;
	/*height: 100%;*/
}

.taskDocs span {
	position: absolute;
	right: 5px;
	top: 0;
	font-size: 12px;
	font-family: var(--headerFontFamily);
}

.dropInput {
	position: absolute;
	width: 100%;
	height: 90%;
	display: none;
}

.dropZone {
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(1,1,1,0.6);
	outline: 2px dashed var(--inputGray);
	z-index: 2;
}

.dropZone p {
	color: white;
	text-align: center;
	line-height: 50px;
}




.leadContainer {
	margin-top: 35px;
	margin-bottom: 20px;
	padding: 0 22px 0 22px;
	font-family: var(--componentFontFamily);
	color: var(--standartDarkGreyColor);
}

.leadContainer input {
	border: 1px solid #F5F5F5;
	border-radius: 6px;
	width: 100%;
	height: 32px;
	outline: none;
	padding-left: 5px;
	transition: border var(--superFastAnimationDuration) ease-in-out;
}

.leadContainer input:focus {
	border: 1px solid var(--accentGray);
}

.leadContainer textarea {
	font-family: var(--dropdownFontFamily);
	resize: none;
	height: 100px;
	width: 100%;
	border: 1px solid #F5F5F5;
	border-radius: 6px;
	outline: none;
	padding-left: 5px;
	padding-top: 5px;
	transition: border var(--superFastAnimationDuration) ease-in-out;
}

.leadContainer textarea:focus {
	border: 1px solid var(--accentGray);
}


.leadContainer div:last-child div p {
	margin-bottom: 5px;
}

.leadBody {
	gap: 10px;
	display: flex;
	flex-direction: column;
}

.column {
	display: flex;
	gap: 5px;
	justify-content: space-between;
}

.row {
	gap: 10px;
	margin-bottom: 10px;
	flex-basis: 45%;
	display: flex;
	flex-direction: column;
}

.row p {
	cursor: default;
}

.row textarea{
	font-size: 14px;
	font-family: var(--componentFontFamily);
}

.source {
	/*position: absolute;*/
	display: block;
}

.source div {
	margin-top: 0;
	display: block;
	height: 32px;
	width: 100%;
}

.source div div {
	display: block;
}

.topic {
	/*position: absolute;*/
	display: block;
}

.topic div {
	margin-top: 0;
	display: block;
	height: 32px;
	width: 100%;
}

.topic div div {
	text-align: center;
	display: block;
}

.topic p {
	cursor: pointer !important;
	line-height: 30px;
}

.leadDate {
	box-shadow: var(--defaultBoxShadow);
	cursor: pointer;
}

.costDropdownOptionsList {
	position: absolute;
	top: 0;
	z-index: 4;
	/*width: 100%;*/
	/*padding-top: calc(2.4em + 1px);*/
	/*font-family: var(--componentFontFamily);*/
	/*font-size: 0.8125em;*/
	/*font-weight: 500;*/
	/*border-radius: var(--defaultBorderRadius);*/
	/*overflow: hidden;*/
	/*background-color: var(--componentBackgroundColor);*/
	/*z-index: 1;*/
	/*box-shadow: var(--defaultBoxShadow);*/
	/*height: 200px;*/
	/*overflow-y: scroll;*/
}

.cost {
	display: flex;
	gap: 10px;
}

.costDropdown {
	flex-basis: 30%;
	padding: 2px 2px 2px 2px !important;
}

.costDropdown button {
	width: 100%;
}

.cost input {
	flex-basis: 100%;
}

.inputMessage {
	min-height: 30px;
	resize: none;
	padding: 10px 10px 10px 10px;
	flex-basis: 100%;
	max-width: 100%;
	outline: none;
	border: 1px solid #F5F5F5;
	border-radius: 4px;
	transition: border var(--superFastAnimationDuration) ease-in-out;
}

.inputMessage:focus {
	border: 1px solid var(--accentGray);
}

.additionOptions {
	flex-basis: 50%;
}

.leftSideButtonsGroup {

}

.leftSideButtonsGroup h1 {
	font-size: 22px;
	line-height: 35px;
	font-family: var(--componentFontFamily);
}

.actionComment {

}

.actionComment div {
	min-height: 120px;
	white-space: pre-wrap;
	overflow-y: hidden;
	overflow-x: hidden;
	font-size: 16px;
	line-height: 22px;
	width: 100%;
	padding: 0.35em 0.5em 0.35em 0;
	resize: none;
	margin-bottom: 25px;
	font-family: var(--componentFontFamily);
	color: var(--darkBackgroundColor);
	border: 1.5px solid #ffffff00;
	border-radius: var(--smallBorderRadius);
	outline: none;
	transition: border var(--superFastAnimationDuration) ease-in, padding var(--superFastAnimationDuration) ease-in;
}

.actionComment div:focus {
	padding: 0.35em 0.25em 0.35em 0.25em;
	border: 1.5px solid var(--inputGray);
}

.optionsList {
	position: absolute;
	top: 100%;
	right: 0;

	font-family: var(--headerFontFamily);
	background-color: var(--componentBackgroundColor);
	border-radius: var(--defaultBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	z-index: 1;
	cursor: pointer;
	color: #AC0600; /* refactor after adding new functionality	*/
}

@keyframes show {
	0%{
		transform: scale(0.1);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@media screen and (max-width: 800px) {
	.leftSideWindow {
		display: none;
	}

	.rightSideWindow {
		display: none;
	}

	.tripleWindowTask .centralWindow {
		margin: 0;
	}

	.windowLabel {
		display: none;
	}
	.tripleWindowTask .centralWindow {
		width: 100%;
		height: 100%;
	}

	.tripleWindowTask .centralWindow .card .cardBody {
		max-height: calc(100vh - 70px);
	}
	.tripleWindowTask .centralWindow .card {
		min-width: auto;
	}

	.modalWindowDefaultSize {
		max-width: none;
		max-height: none;
	}

	.cardHeader .headerButton {
		display: none;
	}

	.tripleWindowTask .centralWindow .card .cardBody {
		height: 100%;
	}
	.createNewTaskButton {
		transform: scale(1.3);
		position: relative !important;
		bottom: 20px !important;
		right: 20px !important;
	}
}

@media screen and (max-width: 633px) {
	.taskForm > div:nth-last-child(2) {
		display: none !important;
	}
}

/*@media screen and (max-width: 1765px){*/
/*	.tripleWindowTask .leftSideWindow > div:last-child{*/
/*		width: 380px;*/
/*	}*/
/*	.tripleWindowTask .leftSideWindow.show {*/
/*		animation: leftSideWindowBecomes 500ms ease-in-out forwards;*/
/*	}*/

/*	.tripleWindowTask .leftSideWindow {*/
/*		animation: leftSideWindowHide 500ms ease-in-out forwards;*/
/*	}*/

/*	.tripleWindowTask .rightSideWindow {*/
/*		animation: rightSideWindowBecomes 500ms ease-in-out forwards;*/
/*	}*/
/*}*/

/*@keyframes leftSideWindowBecomes {*/
/*	0%{*/
/*		left: 392px;*/
/*	}*/
/*	50% {*/
/*		left: 0;*/
/*	}*/
/*	100%{*/
/*		left: 392px;*/
/*		z-index: 4;*/
/*	}*/
/*}*/

/*@keyframes leftSideWindowHide {*/
/*	0%{*/
/*		left: 392px;*/
/*		z-index: 4;*/
/*	}*/
/*	50% {*/
/*		left: 0;*/
/*	}*/
/*	100%{*/
/*		left: 392px;*/
/*	}*/
/*}*/

/*@keyframes rightSideWindowBecomes {*/
/*	!*0%{*!*/
/*	!*	right: 392px;*!*/
/*	!*}*!*/
/*	!*100%{*!*/
/*	!*	right: 0;*!*/
/*	!*	!*z-index: 4;*!*!*/
/*	!*}*!*/
/*}*/

@media screen and (max-width: 500px){
	.wrapper {
		/*height: 91vh;*/
		/*margin-bottom: 50px;*/
	}
}
