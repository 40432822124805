.container {
	position: relative;
	font-family: var(--componentFontFamily);
}

.priority {
	display: inline-block;
	position: relative;
	padding: 0.2666em 0.5em;
	cursor: pointer;
	transition: background-color .1s ease-in;
}

.priority:hover {
	background-color: #f7f7f7;
}

.priority.disabled {
	cursor: default;
}

.priority > p {
	font-weight: 500;
}

.priorityDisabled {
	display: inline-block;
	position: relative;
	padding: 0.2666em 0.5em;
	cursor: default;
	transition: background-color .1s ease-in;
}

.priorityDisabled.disabled {
	cursor: default;
}

.priorityDisabled > p {
	font-weight: 500;
}

.primaryBackground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--componentBackgroundColor);
	opacity: 1;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: var(--smallBorderRadius);
	opacity: 0.1;
}

.arrow {
	position: absolute;
	top: 0.9em;
	right: 0.5em;
	height: 0.322em;
	margin-left: 1em;
	transform: rotate(0);
	z-index: 2;
	transition: transform var(--fastAnimationDuration) ease-in,
		opacity var(--fastAnimationDuration) ease-in;
	pointer-events: none;
}

.arrow.isOpen {
	transform: rotate(180deg);
}

.maker {
	margin-right: 0.5em;
}

.prioritiesList {
	position: absolute;
	top: -0.2em;
	border-radius: var(--smallBorderRadius);
	overflow: hidden;
	box-shadow: var(--defaultBoxShadow);
	z-index: 3;
	background: white;
}

.priorityListElement {
	display: block;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.priorityListElement:not(:last-child) {
	border-bottom: 1px solid var(--backgroundDarkGray);
}

.priorityListElement p {
	position: relative;
	z-index: 3;
}

.priorityListElement::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 4;
}

.priorityListElement > .background {
	border-radius: 0;
}

.priorityListElement:not(:first-child) > .background {
	opacity: 0;
}

.priorityListElement:hover > .background {
	opacity: 0.1;
}
