.container {
	position: relative;
	font-size: 16px;
}

.button {
	padding: 0.5em 0.625em;
	line-height: 1;
	font-size: 1em;
	font-family: var(--dropdownFontFamily);
	font-variation-settings: var(--fontWeightVariableLight);
	border: none;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	outline: none;
	cursor: pointer;
	transition: background-color .1s ease-in;
	user-select:none;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.button p {
	width: 85%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button:hover{
	background: #f7f7f7;
}


.button::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: var(--defaultBorderRadius);
}

.buttonWithOutShadow {
	padding: 0.5em 0.625em;
	line-height: 1;
	font-size: 1em;
	font-family: var(--dropdownFontFamily);
	font-variation-settings: var(--fontWeightVariableLight);
	border: none;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	outline: none;
	cursor: pointer;
	transition: background-color .1s ease-in;
}

.buttonWithOutShadow::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: var(--defaultBorderRadius);
}

.arrow {
	vertical-align: middle;
	height: 0.322em;
	margin-left: 1em;
	transform: rotate(0);
	transition: transform var(--fastAnimationDuration) ease-in,
		opacity var(--fastAnimationDuration) ease-in;
}

.arrow path {
	stroke: var(--darkTextColor);
}

.arrow.isOpen {
	transform: rotate(180deg);
}

.arrow.hideWithoutHover {
	opacity: 0;
}

.button:hover > .arrow.hideWithoutHover {
	opacity: 1;
}

.arrow.isOpen.hideWithoutHover {
	opacity: 1;
}

.dropdownMenu {
	display: inline-block;
	position: absolute;
	top: 2.5em;
	left: 0;
	z-index: 2;
	border-radius: var(--smallBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	background-color: var(--componentBackgroundColor);
}

.dropdownMenu > .option {
	padding: 0 1em;
	line-height: 2em;
	cursor: pointer;
}

.dropdownMenu > .option:not(:last-child) {
	border-bottom: 1px solid var(--accentGray);
}

.checkbox {
	margin-right: 10px;
}

@media screen and (max-width: 1600px) {

}
