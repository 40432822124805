.container {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	border-radius: var(--defaultBorderRadius);
	cursor: text;
}

.container::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loupeSVG {
	height: 100%;
	width: 1em;
	filter: var(--filterConverterForDarkSVGtoGray);
	user-select: none;
}

.inputField {
	position: relative;
	width: calc(100% - 25px);
	height: 100%;
	z-index: 2;
	border: none;
	background-color: #ffffff00;
}

.inputField:focus {
	outline: none;
}

.inputEraser {
	padding-left: 10px;
}

.eraserIcon {
	z-index: 3;
	cursor: pointer;
	width: 24px;
	height: auto;
	opacity: 0.5;
}
