.container {
  display: inline-block;
  margin-left: 2.5em;
  position: absolute;
  top: 0.25em;
  cursor: pointer;
  border-radius: var(--defaultBorderRadius);
  padding: 0.15em 0.15em 0 0.15em;
  transition: var(--fastAnimationDuration);
}
.container:hover {
  box-shadow: var(--defaultBoxShadow);
}
