.container {
	position: relative;
	object-fit: cover;
	height: 100%;
	background-color: inherit;
	user-select: none;
}

.rounded {
	border: 2px solid var(--componentBackgroundColor);
	border-radius: 9999em;
}

/*.link:focus{*/
/*	border: 1px solid black;*/
/*}*/

/*.link:focus-visible{*/
/*	border: 1px solid black;*/
/*}*/

.roundedLoading {
	border: 2px solid var(--componentBackgroundColor);
	border-radius: 9999em;
	opacity: 0.2;
	transition: opacity .1s ease-in;
}

.squared {
	border: 2px solid var(--componentBackgroundColor);
	border-radius: var(--defaultBorderRadius);
}

.squaredLoading {
	border: 2px solid var(--componentBackgroundColor);
	border-radius: var(--defaultBorderRadius);
	opacity: 0.2;
	transition: opacity .1s ease-in;
}
