.wrapper{
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
    background-color: rgba(26, 26, 26, 0.9);
    transition: opacity var(--fastAnimationDuration) ease-in;
    animation: superShow var(--fastAnimationDuration) ease-in;
    visibility: visible;
    right: 0;
    left: 0;
    padding-left: 250px;
}

@media screen and (max-width: 1600px) {
    .wrapper {
        padding-left: 0;
    }
}


.wrapper.hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity var(--fastAnimationDuration) ease-in;
}

.notificationWindow {
    border-radius: var(--defaultBorderRadius);
    overflow: hidden;
    background-color: var(--componentBackgroundColor);
    color: var(--darkBackgroundColor);
}

.modalWindowDefaultSize {
    position: relative;
    min-width: 25%;
    max-width: 50%;
    max-height: 80vh;
}

.modalWindowBigSize {
    position: relative;
    min-width: 25%;
    max-width: 100%;
    /*max-height: 80vh;*/
}

.modalDefaultHeader {
    position: relative;
    height: 3.437em;
    padding: 1.5em 1.375em 0.3125em 1.375em;
    font-family: var(--componentFontFamily);
}

.modalImageHeader {
    color: white;
    background: rgba(0, 0, 0, 0.51);
    width: 100%;
    height: 50px;
    z-index: 3;
    border-radius: 10px 10px 0 0;
}

.modalImageHeader h2 {
   text-align: center;
    line-height: 2;
}

.modalImageCloseButton {
    position: absolute;
    line-height: 0.5;
    font-size: 2em;
    top: 0.5em;
    right: 0.8em;
    cursor: pointer;
}

.closeButton {
    position: absolute;
    line-height: 0.5;
    font-size: 2em;
    top: 0.85em;
    right: 0.8em;
    cursor: pointer;
}

.imageWindow .body {
    font-family: var(--componentFontFamily);
}

.notificationWindow .body {
    padding: 1em 1.375em;
    font-family: var(--componentFontFamily);
}

.notificationWindow .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.625em 1.438em;
}

.notificationWindow .footer .okButton {
    padding: 0 2.5em;
    line-height: 2;
    font-weight: 500;
}

.image {
    object-fit: contain;
    /*width: 100%;*/
    max-width: 100vw;
    max-height: 700px;
    border-radius: 0 0 10px 10px;
    user-select: none;
}

.leftSide {
    opacity: 0;
    position: absolute;
    background: white;
    z-index: 2;
    width: 50%;
    height: calc(100% - 50px);
}

.rightSide {
    opacity: 0;
    position: absolute;
    background: black;
    z-index: 2;
    right: 0;
    width: 50%;
    height: calc(100% - 50px);
}

.error {
    background: #4c494c;
    color: white;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 0 0 10px 10px;
}

.error h2 {
    margin-bottom: 20px;
    padding-top: 10px;
}

.downloadButton {
    background-color: white;
    color: var(--accentCrimsonColor);
    z-index: 2;
    padding: 0.25em 4em;
    margin-bottom: 20px;
}

.downloadButton:hover {
    color: white;
}

@keyframes superShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 1600px) {
    .wrapper {

    }
}
