.container {
	position: absolute;
	line-height: 0.8em;
	cursor: pointer;
  background-color: var(--componentBackgroundColor);
  z-index: 1;
}

.boxShadowContainer {
	white-space: nowrap;
	height: 1.9em;
	min-width: 1em;
}

.boxShadowContainer > p {
	display: inline-block;
	position: relative;
	font-size: 0.85em;
	line-height: 1.35em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: auto;
	max-width: 0;
	color: var(--darkBackgroundColor);
	font-variation-settings: var(--fontWeightVariableNormal);
	cursor: text;
	z-index: 1;
	outline: none;
}

.boxShadowContainerInput{
	white-space: nowrap;
	height: 1.9em;
	min-width: 1em;
	display: flex;
	align-items: flex-end;
}



.showInput {
	margin-left: 10px;
	justify-self: center;
	display: inline-block;
	position: relative;
	font-size: 0.85em;
	line-height: 1.35em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: var(--darkBackgroundColor);
	font-variation-settings: var(--fontWeightVariableNormal);
	cursor: text;
	z-index: 1;
	outline: none;
	border: none;
	width: 150px;
	transition: all .3s ease-in-out;
}

.hideInput {
	justify-self: center;
	display: inline-block;
	position: relative;
	font-size: 0.85em;
	line-height: 1.35em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: var(--darkBackgroundColor);
	font-variation-settings: var(--fontWeightVariableNormal);
	cursor: text;
	z-index: 1;
	outline: none;
	border: none;
	width: 0;
	transition: all .3s ease-in-out;
}

.container::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	max-width: calc(2.35em - 4.5px);
}

.container:hover > .boxShadowContainer > p,
.container.isOpen > .boxShadowContainer > p {
	max-width: calc((100vw - 300px) / 2);
	transition: max-width var(--fastAnimationDuration) ease-in;
}

.githubSVG {
	width: calc(1.55em - 4.5px);
	transition: margin-right var(--fastAnimationDuration) ease-in;
}

.container:hover .githubSVG,
.container.isOpen .githubSVG {
	margin-right: 0.8em;
}

.gitHubRed {
	width: calc(1.55em - 4.5px);
	filter: var(--filterConverterForDarkSVGtoRed);
}
