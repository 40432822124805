@import url('assets/fonts/Raleway.css');
@import url('assets/fonts/SF_UI.css');
@import url('assets/fonts/Montserrat.css');


body {
    margin: 0;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: fixed;
}

* {
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-moz-document url-prefix() {
    * {
        scrollbar-color: var(--accentGray) rgba(0, 0, 0, 0);
        scrollbar-width: auto;
    }
}

*,
*:after,
*:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

/*SAFARI IS PEACE OF SHIT, THAT THINKS USER-SELECT IS SOMETHING THAT PREVENTS TYPING*/
/*input[placeholder] {*/
/*    user-select: none;*/
/*}*/

:root {
    --componentBackgroundColor: #ffffff;
    --darkBackgroundColor: #111719;
    --darkTextColor: #222222;
    --darkBackgroundHoverColor: #353839;
    --backgroundGray: #fafafa;
    --accentGray: #c5c5c5;
    --lightGrayBorder: #f5f5f5;
    --backgroundDarkGray: #e5e5e5;
    --inputGrayBackground: #262c2e;
    --inputGray: #a1a1a1;
    --accentInputGray: #868686;
    --inputDark: #5f5f5f;
    --darkGreyBackground: #f1f1f1;
    --borderDark: #343839;
    --labelGray: #464646;
    --modalWindowBackground: #2222224a;
    --accentCrimsonColor: #d2486d;
    --deadlineColor: #af2525;
    --declineColor: #e16060;
    --red: #e16060;
    --green: #a9e086;
    --greenAccent: #44ac00;
    --greenDone: #94d769;
    --yellow: #ffe482;
    --yellowVerification: #ffc400;
    --standartDarkGreyColor: #464646;
    --standartBlackColor: #111719;
    --defaultBoxShadow: 0 0 6px #0000001a;
    --headerBoxShadow: 0px 3px 4px #41212d1a;
    --modalWindowBoxShadow: 0px 0px 10px #0000000d;
    /* tool: https://codepen.io/sosuke/pen/Pjoqqp */
    --filterConverterForDarkSVGtoWhite: invert(100%) sepia(0%) saturate(1%) hue-rotate(234deg) brightness(108%) contrast(200%);
    --filterConverterForDarkSVGtoGray: invert(76%) sepia(1%) saturate(0%) hue-rotate(169deg) brightness(86%) contrast(87%);
    --filterConverterForDarkSVGtoDarkGray: invert(13%) sepia(13%) saturate(541%) hue-rotate(149deg) brightness(91%) contrast(87%);
    /*--filterConverterForDarkSVGtoWhite: invert(1);*/
    --filterConverterForDarkSVGtoRed: invert(15%) sepia(87%) saturate(2523%) hue-rotate(346deg) brightness(105%) contrast(92%);
    --filterConverterForGraySVGtoRed: invert(99%) sepia(43%) saturate(2762%) hue-rotate(346deg) brightness(105%) contrast(92%);
    --filterConverterForGraySVGtoWhite: invert(0%) sepia(0%) saturate(1%) hue-rotate(234deg) brightness(108%) contrast(200%);
    --sfuiBold: 'SF UI Bold', bold;
    --ralewayBold: 'Raleway Bold', bold;
    --ralewayMedium: 'Raleway Medium', medium;
    --headerFontFamily: 'Raleway', sans-serif;
    --componentFontFamily: 'SF UI Display', sans-serif;
    --sortedHeaderFontFamily: 'Segoe UI', sans-serif;
    --dropdownFontFamily: 'Raleway', medium;
    --daysFontFamily: 'Montserrat', medium;
    --fontWeightVariableBold: 'wght' 650;
    --fontWeightVariableNormal: 'wght' 600;
    --fontWeightVariableLight: 'wght' 500;
    --fontWeightVariableThin: 'wght' 300;
    --defaultBorderRadius: 6px;
    --smallBorderRadius: 4px;
    --extremelySmallBorderRadius: 2px;
    --slowAnimationDuration: 0.4s;
    --fastAnimationDuration: 0.2s;
    --superFastAnimationDuration: 0.1s;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--accentGray);
    border-radius: 999em;
}
