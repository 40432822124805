body{
    background: #FAFAFA;
}

.container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;
}

.LogoSvg{
    width: 312px;
    height: 60px;
}

.loginContainer{
    margin-top: 38px;
    width: 375px;

    background-color: var(--componentBackgroundColor);
    box-shadow: 0px 0px 10px #0000001A;
    border-radius: 6px;
    opacity: 1;
    transition: all 0.1s ease-in-out;
}

.loginContainerTransparent{
    margin-top: 38px;
    width: 375px;
    /*height: 428px;*/
    background-color: var(--componentBackgroundColor);
    box-shadow: 0px 0px 10px #0000001A;
    border-radius: 6px;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;
}

.loginContainerRegistration{
    margin-top: 38px;
    width: 375px;
    height: 560px;
    background-color: var(--componentBackgroundColor);
    box-shadow: 0px 0px 10px #0000001A;
    border-radius: 6px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.header{
    display: flex;
}

.header h1{
    font-weight: bold;
    margin-top: 30px;
    font-size: 22px;
    margin-left: 66px;
    font-family: var(--componentFontFamily);
    cursor: pointer;
}

.header h1:last-child{
    margin-right: 66px;
}

.headerInactive{
    color: #C5C5C5;
}

.inputContainer{
    padding: 51px 20px 0px 20px;
}

.inputContainer input::placeholder {
    color: var(--accentGray);
    font-size: 16px;
}

.authorizeButton{
    margin: 38px 20px 0 20px;
    width: 335px;
    height: 40px;
    padding: 0;
    font-family: var(--headerFontFamily);
    font-size: 14px;
}

.forgotPassword{
    text-align: center;
    margin: 35px 0 36px 0;
    font-family: var(--headerFontFamily);
}

.forgotPassword a{
    color: #D2486D;
    cursor: pointer;
}

.dataInput:last-child{
    margin-top: 24px;
}

.dataInput input{
    border-color: #F5F5F5;
}

.dataInput div{
    color: #A1A1A1;
    font-family: var(--headerFontFamily);
    font-size: 15px;
    font-weight: normal;
}

.loader {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.loader .spinner {
    pointer-events: none;
    width: 5.5em;
    height: 5.5em;
    border: 0.7em solid transparent;
    border-color: var(--accentGray);
    border-top-color: var(--accentCrimsonColor);
    opacity: 0.7;
    border-radius: 50%;
    animation: loadingSpinnerRotate 1s linear infinite;
}

.errors{
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    /*gap: 10px;*/
}

.errors > div:last-child {
    margin-top: 10px;
}

.warning {
    display: flex;
    /*gap: 10px;*/
    align-items: center;
}

.warning > p {
    margin-left: 10px;
}

.warningIcon {
    width: 25px;
    filter: var(--filterConverterForDarkSVGtoRed);
}

@keyframes loadingSpinnerRotate {
    100% {
        transform: rotate(360deg);
    }
}
