.taskName{
    display: inline-block;
    resize: none;
    max-width: 640px;
    font-size: 20px;
    width: auto;
    padding: 0.35em 0.5em 0.35em 0;
    margin-bottom: 35px;
    font-family: var(--componentFontFamily);
    font-weight: 700;
    color: var(--darkBackgroundColor);
    border: 1.5px solid #ffffff00;
    border-radius: var(--smallBorderRadius);
    outline: none;
    transition: border var(--superFastAnimationDuration) ease-in,
    padding var(--superFastAnimationDuration) ease-in;
    white-space: pre-wrap;
    overflow: hidden;
}

.taskName[placeholder]:empty:before {
    content: attr(placeholder);
    color: var(--inputGray);
}

.taskName.isEdit {
    padding: 0.35em 0.25em 0.35em 0.25em;
    border: 1.5px solid var(--inputGray);
}

.notAllowedCursor {
    cursor: default;
}
[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
}
