.container {
	width: 100%;
	height: 100px;
	padding: 20px 30px;
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--headerBoxShadow);
	font-family: var(--headerFontFamily);
	display: flex;
}

.projectInformation {
	display: inline-block;
	width: 50%;
}

.searchBarAndPeoples {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;
}

.searchBar {
	font-size: 15px;
	line-height: 18px;
	height: 2.2em;
	width: 220px;
	padding: 0.5333em 0.6667em;
	margin-right: 32px;
}

.userRank {
	/*margin-right: 25px;*/
}

@media screen and (max-width: 1600px) {
	/*.searchBar {*/
	/*	width: 2.5em;*/
	/*}*/
}

.peoplesBlock {
	display: inherit;
	position: relative;
	font-size: 32px;
}

@media screen and (max-width: 1600px) {
	.peoplesBlock {
		display: none;
	}
	.userRank {
		display: none;
	}
	.searchBarAndPeoples > div:first-child{
		display: none;
	}
}

.addPeopleButton {
	display: inline-block;
	position: relative;
	height: 1em;
	width: 1em;
	/*top: 0;*/
	/*right: 0;*/
	transform: rotate(45deg);
	text-align: center;
	line-height: 0.8em;
	color: var(--accentGray);
	border: 1px solid var(--accentGray);
	border-radius: 9999rem;
	cursor: pointer;
	transition: background-color, color, 100ms ease-in;
	margin-right: 15px;
}

.addPeopleButton:hover {
	background: var(--accentGray);
	color: white;
}

.addPeopleButton::after {
	content: '';
	position: relative;
	top: 1.2em;
	right: -0.5em;
	transform: rotate(-45deg);
	height: 1em;
	width: 4.4em;
}

.exitButton {
	padding: 0 15px 0 15px;
}

.menuToggle {
	display: none;
}

.hamburger {
	display: none;
}

/*@media screen and (max-width: 1450px){*/
/*	.container {*/
/*		width: 100%;*/
/*		!*height: 150px;*!*/
/*		padding: 20px 30px;*/
/*		background-color: var(--componentBackgroundColor);*/
/*		box-shadow: var(--headerBoxShadow);*/
/*		font-family: var(--headerFontFamily);*/
/*	}*/
/*}*/

.battery {
	width: 30px;
	height: auto;
	margin-right: 20px;
}

@media screen and (max-width: 1600px) {
	.projectInformation {
		width: 100%;
	}
	.searchBar {
		display: none;
	}

	.container {
		padding: 20px 75px;
	}
	.hamburger {
		display: block;
	}

}
