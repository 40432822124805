.container {
    background-color: var(--componentBackgroundColor);
}

.languageSwitcher {
    display: flex;
    justify-content: center;
}

.languageSwitcher > div {
    margin: 15px 0 0 0;
    z-index: 3;
}

/*.item:first-child {*/
/*    height: 80px;*/
/*}*/

.item {
    height: 40px;
    /*border-bottom: 1px solid var(--accentGray);*/
    display: flex;
}

.content {
    margin: auto;
}

.content div {
    margin: auto;
}

.item h1 {
    margin: auto;
    font-family: var(--headerFontFamily);
    font-size: 18px;
}

.searchBar {
    font-size: 15px;
    line-height: 18px;
    height: 2.2em;
    width: 220px;
    padding: 0.5333em 0.6667em;
    margin-right: 32px;
}

.accentButton {
    top: 0;
    right: 0;
    font-size: 16px;
}

.dropdownButton {
    font-size: 16px;
    display: inline-block;
    margin-right: 1.5em;
}

.dropdownButton > button {
    text-align: justify;
    width: 100%;
    box-shadow: none;
    height: 50px;
}

.dropdownButton > button > svg {
    position: absolute;
    right: 0.625em;
    top: 1.4em;
}

.dropdownButton > button.dropdownButtonIsOpen {
    position: relative;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 4;
    box-shadow: none;
}

.dropdownButton.taskStatusFilter {
    width: 100%;
    z-index: 5;
    margin-top: 20px;
}

.dropdownButton.taskTypeFilter {
    width: 100%;
    z-index: 4;
     margin-top: 10px;
}

.dropdownButton.taskDateFilter {
    width: 100%;
    z-index: 3;
     margin-top: 10px;
}

.dropdownButton.taskThemeFilter {
    width: 100%;
    z-index: 2;
     margin-top: 10px;
}

.dropdownButton.userTasksFilter {
    width: 100%;
     margin-top: 10px;
}

.dropdownButton .dropdownButtonOptionList {
    position: absolute;
    top: 18px;
    width: 100%;
    padding-top: calc(2.4em + 1px);
    font-family: var(--componentFontFamily);
    font-size: 0.8125em;
    font-weight: 500;
    border-radius: var(--defaultBorderRadius);
    overflow: hidden;
    background-color: var(--componentBackgroundColor);
    z-index: 1;
    box-shadow: var(--defaultBoxShadow);
}

.dropdownButtonOptionList > .dropdownButtonOptionListElement {
    padding: 0.6923em 0.7692em;
    border-top: 1px solid var(--lightGrayBorder);
    cursor: pointer;
}

.dropdownButtonOptionList > .dropdownButtonOptionListElement:hover {
    background-color: var(--lightGrayBorder);
}

.exitButton {
    position: absolute;
    bottom: 15px;
    width: 70%;
    margin-left: 15%;
    padding: 5px 0;
}
