.peoplesStack {
	position: relative;
	display: flex;
	height: 1em;
	/*margin-right: calc(15px + 1em);*/
	margin-right: 15px;
	cursor: pointer;
}

.peoplesSpacer {
	display: inline-block;
	position: relative;
	width: 0.75em;
	height: 1em;
}

.peoplesSpacer > * {
	position: absolute;
	height: 1em;
	width: 1em;
	left: 0;
	top: 0;
	background-color: var(--componentBackgroundColor);
}

.lastStackBlock {
	background-color: var(--backgroundDarkGray);
	border-radius: 99999em;
	line-height: 1em;
}

.lastStackBlock > p {
	font-size: 14px;
	font-family: var(--componentFontFamily);
	font-weight: 500;
	text-align: center;
	line-height: inherit;
}
