.usersEditorWindow {
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	color: var(--darkBackgroundColor);
	/* ///!!! HARDCODE !!! --> */
	width: 671px;
	/* ///!!! HARDCODE !!! <-- */
	height: auto;
	animation: show var(--fastAnimationDuration) ease-in;
}

.usersEditorWindow .header {
	position: relative;
}

.usersEditorWindow .body {
	padding: 1em 1.375em;
	font-family: var(--componentFontFamily);
}

.usersEditorWindow .body .search {
	position: relative;
	height: 2em;
	padding: 0 0.5em;
	margin-bottom: 1.5em;
	z-index: 3;
}

.usersEditorWindow .body .usersSearchResultList {
	position: absolute;
	top: 6em;
	left: 1.375em;
	width: calc(100% - 2.75em);
	padding-top: 0.75em;
	border: 1px solid var(--accentGray);
	background-color: var(--componentBackgroundColor);
	border-radius: var(--defaultBorderRadius);
	overflow: hidden;
	z-index: 2;
}

.usersEditorWindow .body .usersSearchResultList .listElement {
	height: 2.5em;
	padding: 0.25em 0.5em;
	cursor: pointer;
}

.usersEditorWindow .body .usersSearchResultList .selected {
	height: 2.5em;
	padding: 0.25em 0.5em;
	cursor: pointer;
}

.usersEditorWindow .body .usersSearchResultList .selected:hover {
	background-color: var(--accentGray);
}

.usersEditorWindow .body .usersSearchResultList .selected .avatar {
	vertical-align: middle;
	height: 2em;
	width: 2em;
	margin-right: 1em;
}

.usersEditorWindow .body .usersSearchResultList .selected h4 {
	display: inline-block;
	vertical-align: middle;
	font-family: var(--componentFontFamily);
	font-weight: 500;
	font-size: 0.9em;
}

.usersEditorWindow .body .usersSearchResultList .listElement:hover {
	background-color: var(--lightGrayBorder);
}

.usersEditorWindow .body .usersSearchResultList .listElement .avatar {
	vertical-align: middle;
	height: 2em;
	width: 2em;
	margin-right: 1em;
}

.usersEditorWindow .body .usersSearchResultList .listElement h4 {
	display: inline-block;
	vertical-align: middle;
	font-family: var(--componentFontFamily);
	font-weight: 500;
	font-size: 0.9em;
}



.usersEditorWindow .body .tableHolder {
	/*max-height: 500px;*/
	max-height: 500px;
	position: relative;
	overflow-y: scroll;
	overflow-x: hidden;
	/* height: calc(80vh - 10.7495em);
    /* 3.437em + 1.5em + 0.3125em + (1em * 2) + 2em + 1.5em = 10.7495em */
	/* height: calc(80vh - 10.7495em);*/
}

.tableDisabler {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: var(--componentBackgroundColor);
	opacity: 0;
	z-index: 1;
	transition: opacity var(--fastAnimationDuration) ease-in;
}

.tableDisabler.show {
	width: 100%;
	height: 100%;
	opacity: 0.6;
}

.usersEditorWindow .body table {
	width: 100%;
	empty-cells: hide;
	border-collapse: collapse;
	overflow: hidden;
}

.usersEditorWindow .body table > thead > tr:not(:first-child) {
	width: 100%;
	display: none;
}

.usersEditorWindow .body table > thead th {
	font-size: 0.9375em;
	padding: 0.4em 0.5em;
	font-family: var(--headerFontFamily);
	font-weight: 400;
	text-align: start;
	color: var(--inputGray);
}

.usersEditorWindow .body table > tbody {
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: var(--defaultBorderRadius);
	box-shadow: inset 0 0 0 1px var(--lightGrayBorder);
}

.usersEditorWindow .body table > tbody > tr:last-child {
	display: none;
}

.usersEditorWindow .body table > tbody > tr {
	height: 2em;
	font-family: var(--componentFontFamily);
	font-weight: 500;
	color: var(--darkBackgroundColor);
}

.usersEditorWindow
	.body
	table
	> tbody
	> tr:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
	border-top: 1px solid var(--lightGrayBorder);
	border-bottom: 1px solid var(--lightGrayBorder);
}

.usersEditorWindow .body table > tbody > tr {
	max-height: 2em;
}

.usersEditorWindow .body table > tbody tr td:first-child {
	width: 2em;
	padding: 0.35em 0.1em 0.1em 0.1em;
}

.usersEditorWindow .body table > tbody .tableUserPhoto {
	height: 2em;
	width: 2em;
}

.usersEditorWindow .body table > tbody tr td:nth-child(2) {
	padding: 0.6875em 0.2em;
}

.usersEditorWindow .body table > tbody tr td:nth-child(3) {
	width: 35%;
	padding: 0.6875em 0.2em;
}

.usersEditorWindow .body table > tbody tr td:nth-child(4) {
	width: 5%;
	padding: 0.3em 0.2em 0.1em 0.2em;
}

.usersEditorWindow .body table > tbody .trashButtonContainer {
	position: relative;
	user-select: none;
}

.usersEditorWindow .body table > tbody .trashButton {
	position: relative;
	width: 60%;
	height: 1.5em;
}

.usersEditorWindow .body table > tbody .trashButtonContainer::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.usersEditorWindow
	.body
	table
	> tbody
	.trashButtonContainer:hover
	.trashButton {
	filter: var(--filterConverterForGraySVGtoRed);
}

.animatedSelectedWorkspaceEnterActive{
	opacity: 0;
	transition: opacity, transform var(--superFastAnimationDuration) ease-in;
	transform: translate(0, -100%);
}
.animatedSelectedWorkspaceEnterDone{
	opacity: 1;
	transition: opacity, transform var(--superFastAnimationDuration) ease-in;
	transform: translate(0, 0);
}

.animatedSelectedWorkspaceExit{
	opacity: 1;
	transition: opacity, transform var(--superFastAnimationDuration) ease-in;
	transform: translate(0, 0);
}

.animatedSelectedWorkspaceExitActive{
	opacity: 0.7;
	transform: translate(-100%, 0);
	transition: opacity, transform var(--superFastAnimationDuration) ease-in;
}
.animatedSelectedWorkspaceExitDone{
	opacity: 0;
	transition: opacity, transform var(--superFastAnimationDuration) ease-in;
	transform: translate(-100%, 0);
}

.selected {
	background: var(--lightGrayBorder);
}

@keyframes show {
	0%{
		transform: scale(0.6);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

