.workspaceNotesModalWindowSize {
  position: relative;
	min-width: 50%;
	max-width: 75vw;
	max-height: 95vh;
  height: 100%;
  width: 100%;
}

.closeButton {
  height: 35px;
  width: 35px;
  padding: 7.5px;
}

.closeButton:hover {
  box-shadow: var(--defaultBoxShadow);
}

.workSpaceNotesWindowContainer {
  border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	animation: show var(--fastAnimationDuration) ease-in;
  z-index: 1000;
}

.workspaceNotesBodyContainer {
  height: 100%;
  display: flex;
}

.workspaceNotes {
  overflow-x: hidden;
  position: relative;
  font-size: 16px;
  margin: 1em;
  padding: 0.5em;
  max-width: 85vw;
  text-overflow: ellipsis;
  resize: none;
  font-family: var(--headerFontFamily);
  outline: none;
  border: 0;
  /*color: var(--inputGray);*/
  /*width: 100%;*/
  border-radius: 4px;
  min-height: 50vh;
  max-height: 83vh;
  height: 100%;
  width: 100%;
  /*min-width: 100px;*/
  color: var(--inputBlack);
  z-index: 301;
  cursor: text;
}

.workspaceNotesChangable {
  border: 1px solid grey;
  box-shadow: var(--defaultBoxShadow);
}

.workspaceFileUploadArea {
  padding: 5px;
  margin-top: 1em;
  margin-right: 5px;
  position: relative;
  min-width: 124px;
  max-width: 250px;
  height: 100%;
  max-height: 83vh;
}
