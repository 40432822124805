.container {
    height: 100vh;
    width: 100%;
    position: relative;
}

.header {
    width: 100%;
    height: 100px;
    box-shadow: rgba(65, 33, 45, 0.1) 0px 3px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    user-select: none;
    position: inherit;
}

.header > *:first-child {
    padding-left: 32px;
    align-items: center;
    display: flex;
    flex-basis: 25%;
    white-space: pre;
}

.header > *:last-child {
    padding-right: 32px;
}

.body {
    width: auto;
    padding: 0 32px 20px 32px;
    height: calc(100vh - 100px);
    overflow: auto;
}

.exitButton {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 5px 15px;
}

.item {
    overflow-wrap: break-word;
    /*box-shadow: var(--defaultBoxShadow);*/
    border-radius: 4px;
    margin-top: 20px;
}

.item > div:first-child > div:first-child h1 {
    font-family: var(--sfuiBold);
    font-size: 26px;
    color: #111719;
    margin-bottom: 5px;
}

.item > div:first-child > div {
    margin-bottom: 20px;
}

.item > div:first-child {
    padding: 22px 28px 22px 28px;
}

@media screen and (max-width: 1600px){
    .headerText {
        margin-left: 35px;
    }
}
