.container {
    width: 100%;
    padding-top: 5px;
    background-color: var(--componentBackgroundColor);
    border-radius: 8px;
    /*padding: 0 28px 0 28px;*/
    font-family: var(--componentFontFamily);
}

.container div h1 {
    font-size: 20px;
    color: #111719;
}

.name {
    padding-right: 40px;
    display: flex;
    align-items: center;
}

.name img {
    margin-right: 10px;
    width: 15px;
    margin-left: 10px;
    transition: transform 200ms ease-in-out;
    opacity: 0.5;
}

.name h1 a {
    text-decoration: none;
    color: #111719;
    transition: color var(--superFastAnimationDuration) ease-in;
}

/*.name h1 a img {*/
/*    top: 3px;*/
/*    !*filter: blur(0.8px);*!*/
/*    transform: rotate(45deg);*/
/*    width: 16px;*/
/*    opacity: 0.7;*/
/*    margin-left: 6px;*/
/*}*/

.name h1 a img {
    width: 10px;
    opacity: .9;
    margin-left: 8px;
    filter: var(--filterConverterForDarkSVGtoDarkGray) blur(0.3px);
    transition: all 100ms ease-in-out;
}

.name h1 a:hover {
    color: var(--accentCrimsonColor);
}

.name h1 a:hover img {
    filter: var(--filterConverterForDarkSVGtoRed) blur(0.3px);
    opacity: 0.9;
}

.description {
    font-family: var(--headerFontFamily);
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.isOpened {
    transform: rotate(180deg);
}

.children div div div {
    margin-left: 15px;
}

.children div div div h1 {
    font-size: 18px;
    margin-top: 5px;
}
