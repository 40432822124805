@font-face {
    font-family: 'SF UI Display';
    src: url('./SF_UI/SFUIDisplay-Regular.eot');
    src: local('SF UI Display Regular'), local('SFUIDisplay-Regular'),
        url('./SF_UI/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('./SF_UI/SFUIDisplay-Regular.woff2') format('woff2'),
        url('./SF_UI/SFUIDisplay-Regular.woff') format('woff'),
        url('./SF_UI/SFUIDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('./SF_UI/SFUIDisplay-Bold.eot');
    src: local('SF UI Display Bold'), local('SFUIDisplay-Bold'),
        url('./SF_UI/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('./SF_UI/SFUIDisplay-Bold.woff2') format('woff2'),
        url('./SF_UI/SFUIDisplay-Bold.woff') format('woff'),
        url('./SF_UI/SFUIDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('./SF_UI/SFUIDisplay-Medium.eot');
    src: local('SF UI Display Medium'), local('SFUIDisplay-Medium'),
        url('./SF_UI/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('./SF_UI/SFUIDisplay-Medium.woff2') format('woff2'),
        url('./SF_UI/SFUIDisplay-Medium.woff') format('woff'),
        url('./SF_UI/SFUIDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('./SF_UI/SFUIDisplay-Light.eot');
    src: local('SF UI Display Light'), local('SFUIDisplay-Light'),
        url('./SF_UI/SFUIDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('./SF_UI/SFUIDisplay-Light.woff2') format('woff2'),
        url('./SF_UI/SFUIDisplay-Light.woff') format('woff'),
        url('./SF_UI/SFUIDisplay-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Bold';
    src: url('./SF_UI/SFUIDisplay-Bold.ttf');
}
