.container {
	position: relative;
}


.theme {
	display: inline-block;
	position: relative;
	padding: 0.25em 0.5em;
	border: 1px dotted var(--inputGray);
	border-radius: var(--defaultBorderRadius);
	color: var(--darkTextColor);
	background-color: var(--componentBackgroundColor);
	cursor: pointer;
	width: 110px;
	text-align: center;
	transition: background-color .1s ease-in;
}

.theme:hover {
	background-color: var(--lightGrayBorder);
}

.theme.disabled {
	cursor: default;
}

.themeDisabled{
	display: inline-block;
	position: relative;
	padding: 0.25em 0.5em;
	border: 1px dotted var(--inputGray);
	border-radius: var(--defaultBorderRadius);
	color: var(--darkTextColor);
	background-color: var(--componentBackgroundColor);
	cursor: default;
	width: 110px;
	text-align: center;
	transition: background-color .1s ease-in;
}

.themesList {
	position: absolute;
	min-width: 100%;
	top: 0;
	right: 0;
	z-index: 2;
	border-radius: var(--smallBorderRadius);
	box-shadow: var(--defaultBoxShadow);
	overflow: hidden;
}

.searchBar {
  /*margin-right: 30px;*/
  max-height: 28px;
  padding: 0.4em 0.3em;
  background-color: var(--componentBackgroundColor);
  border: 1px solid #ffffff00;
  border-bottom: 1px solid var(--backgroundDarkGray);
  border-radius: 0;
  color: var(--inputDark);
  transition: background-color var(--superFastAnimationDuration) ease-in,
  color var(--superFastAnimationDuration) ease-in,
  border var(--superFastAnimationDuration) ease-in;
  width: 100%;
}

.searchBar > input {
  font-family: var(--componentFontFamily);
}

.searchBar > object {
  filter: var(--filterConverterForDarkSVGtoDarkGray);
  transition: filter var(--superFastAnimationDuration) ease-in;
}

.searchBar::placeholder {
  color: var(--inputDark);
  transition: color var(--superFastAnimationDuration) ease-in;
}

.searchBar.focus {
  background-color: var(--componentBackgroundColor);
  color: var(--darkTextColor);
  border: 1px solid var(--accentGray);
  border-radius: var(--defaultBorderRadius);
}

.searchBar.focus > object {
  filter: var(--filterConverterForDarkSVGtoGray);
}

.searchBar.focus input::placeholder {
  color: var(--darkTextColor);
}

.themeListElement {
	display: block;
	padding: 0.4em 0.3em;
	border: none;
	border-left: 1.5px solid #ffffff00;
	border-right: 1.5px solid #ffffff00;
	border-radius: 0;
}

/* .themeListElement::before {
	content: '•';
	margin-right: 0.85em;
} */

.themeListElement > p {
	display: inline-block;
}

.themeListElement:not(:last-child) {
	border-bottom: 1px solid var(--backgroundDarkGray);
}

.themeListElement:hover {
	background-color: var(--lightGrayBorder);
	/*text-shadow: 0 0 0.45px var(--darkTextColor);*/
}
