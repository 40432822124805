.fullScreenWrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.errorBlock {
    margin: auto;
    text-align: center;
}

.errorBlock h1 {
    margin-bottom: 20px;
}

.errorBlock h4 {
    margin-bottom: 10px;
    padding: 0 10% 0 10%;
}

.errorBlock input {
    width: 100%;
    border: none;
    cursor: pointer;
}

.spinner {
    position: relative !important;
}
