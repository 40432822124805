.workspaceCreatorWindow{
	opacity: 1;
	animation: show var(--superFastAnimationDuration) ease-in forwards;
}

.workspaceCreatorWindow {
	background-color: var(--componentBackgroundColor);
	border-radius: var(--defaultBorderRadius);
	width: 433px;
}

.workspaceCreatorWindow .body {
	padding: 1.75em 1.375em 0.25em 1.375em;
}

.workspaceCreatorWindow .body .input {
	width: 100%;
	margin-bottom: 1.1875em;
}

.workspaceCreatorWindow .body .inputLabel {
	position: relative;
	font-size: 0.8125em;
	line-height: 1.8;
	font-family: var(--componentFontFamily);
	font-weight: 600;
	color: var(--labelGray);
}

.workspaceCreatorWindow .body .dropdownButtonOptions {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 2.5em;
	background-color: var(--componentBackgroundColor);
	box-shadow: var(--defaultBoxShadow);
	border-radius: var(--defaultBorderRadius);
	z-index: 1;
}

.workspaceCreatorWindow .body .dropdownButton {
	position: relative;
	width: 100%;
	font-size: 1.25em;
	line-height: 2;
	padding: 0 0.8em;
	text-align: left;
	z-index: 2;
}

.workspaceCreatorWindow .body .dropdownButton .arrow {
	position: absolute;
	top: 0.85em;
	right: 0.8em;
}

.workspaceCreatorWindow .body .dropdownButtonOnOpenMenu {
	box-shadow: none;
}

.dropdownButtonOptionListElement {
	font-size: 1.25em;
	line-height: 2;
	padding: 0 0.8em;
	border-top: 1px solid var(--lightGrayBorder);
	cursor: pointer;
}

.workspaceCreatorWindow .footer {
	padding: 1em 1.375em 1.5em 1.375em;
	text-align: right;
}

.workspaceCreatorWindow .footer .okButton {
	padding: 0 1em;
	line-height: 2em;
	outline: none;
}

.workspaceCreatorWindow .footer .declineButton {
	padding: 0 1em;
	line-height: 2em;
	margin-right: 2em;
	border: 0;
	font-family: var(--headerFontFamily);
	font-weight: 650;
	border-radius: var(--defaultBorderRadius);
	background-color: var(--componentBackgroundColor);
	color: var(--darkBackgroundColor);
	outline: none;
	cursor: pointer;
}

.workspaceCreatorWindow .footer .declineButton:hover {
	background-color: var(--backgroundGray);
}

.animatedWorkspacesEnterActive {
	opacity: 0;
	transform: scale(0.3);
}

.animatedWorkspacesEnterDone {
	opacity: 1;
	transition: opacity, transform 150ms;
	transform: scale(1);
}
.animatedWorkspacesExitActive {
	opacity: 1;
	transform: scale(1);
}
.animatedWorkspacesExitDone {
	opacity: 0;
	transition: opacity, transform 150ms;
	transform: scale(0.3);
}

@keyframes show {
	0%{
		transform: scale(0.6);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@media screen and (max-width: 800px) {

}
