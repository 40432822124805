.calendar{
    width: 325px;
    z-index: 4;
    opacity: 0;
}

.calendar:before{
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    margin-right: 15px;
    transform: rotate(45deg);
    top: -10px;
    right: 0;
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    z-index: -1;
}

.calendar:after{
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    margin-right: 15px;
    transform: rotate(45deg);
    top: -10px;
    right: 0;
    background-color: var(--componentBackgroundColor);
    z-index: 1;
}

.calendarModal{
    width: 325px;
    z-index: 4;
    opacity: 0;
}

.calendarModal:before{
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    margin-right: 15px;
    transform: rotate(45deg);
    top: -10px;
    left: 10px;
    background-color: var(--componentBackgroundColor);
}

.container{
    width: 325px;
    box-shadow: 0px 0px 6px #00000021;
    border-radius: 5px;
    background: white;
    padding-top: 15px;
    z-index: 4;
}

.header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
}

.body{
    padding: 0px 20px 20px 20px;
}

.body div:last-child {
    margin-bottom: 0;
}

.days {
    width: 100%;
    display: flex;
    border-bottom: 3px solid #f5f5f5;
    margin-bottom: 20px;
    /*gap: 15px;*/
    padding-bottom: 10px;
    font-family: var(--headerFontFamily);
}

.days p {
    color: #A1A1A1;
    text-align: center;
    flex-basis: 27px;
}

.days p:not(:first-child) {
    margin-left: 15px;
}

.date {
    width: 100%;
    display: flex;
    /*gap: 15px;*/
    margin-bottom: 15px;
    font-family: var(--daysFontFamily);
}

.date p:not(:first-child) {
    margin-left: 15px;
}

.show {
    line-height: 27px;
    text-align: center;
    flex-basis: 27px;
    cursor: pointer;
    height: 27px;
    transition: background-color .3s ease-in-out;
    background: none;
    border-radius: 100%;
}

.show:hover{
    color: white;
    background: var(--accentCrimsonColor);
    border-radius: 100%;
}

.nowDate {
    transition: all .3s ease-in-out;
}

.nowDate:hover{
    color: white;
    background: var(--accentCrimsonColor);
    border-radius: 100%;
}

.hide{
    flex-basis: 27px;
    cursor: default;
    height: 27px;
    color: white;
}

.selected {
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    flex-basis: 27px;
    cursor: pointer;
    height: 27px;
    transition: all .2s linear;
    border-bottom: 2px solid var(--accentCrimsonColor);
}

.nowDate{
    text-align: center;
    flex-basis: 27px;
    cursor: pointer;
    height: 27px;
    background: #ebebeb;
    border-radius: 100%;
    line-height: 27px;
}

.monthText{
    font-family: var(--componentFontFamily);
    font-size: 26px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 5px;
}

/*.monthText p {*/
/*    position: absolute;*/
/*    top: 52px;*/
/*    left: 145px;*/
/*    font-size: 15px;*/
/*}*/

.leftArrow {
    height: 20px;
    transform: rotate(180deg);
    margin-left: 17px;
    margin-top: 0;
}

.rightArrow {
    height: 20px;
    margin-right: 17px;
    margin-top: 0;
}

.modalShowModal {
    opacity: 1;
    left: 0;
}

.modalShowingModal {
    animation: superShow var(--fastAnimationDuration) ease-in;
    left: 0;
}

.modalShow {
    opacity: 1;
}

.modalClose {
    opacity: 0;
}

.modalShowing {
    animation: superShow var(--fastAnimationDuration) ease-in;
}

.modalClosing {
    opacity: 0;
    transition: opacity var(--fastAnimationDuration) ease-in;
}

.reversed {
    bottom: 45px;
}

.reversed:before, .reversed:after {
    bottom: -15px;
    top: auto;
}

.arrow {
    position: relative;
    z-index: 2;
}

.arrow:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 60px;
    height: 65px;
}

@keyframes superShow {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/*.date {*/
/*    cursor: pointer;*/
/*    width: 30px;*/
/*}*/

/*.date:hover{*/
/*    background: #F5F5F5;*/
/*    border-radius: 100%;*/
/*}*/
