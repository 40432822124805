.button {
	position: relative;
	line-height: 2em;
	padding: 0 1em;
	border: none;
	border-radius: var(--defaultBorderRadius);
	color: var(--componentBackgroundColor);
	background-color: var(--accentCrimsonColor);
	cursor: pointer;
	outline: none;
	transition: background-color 0.2s ease-in-out;
	user-select:none;
}

.button:hover:not(.buttonError) {
	background: #B93B5D;
	color: var(--componentBackgroundColor);
}

.buttonError {
	padding: 0 1em;
	line-height: 2em;
	outline: none;
	position: relative;
	border: none;
	border-radius: var(--defaultBorderRadius);
	color: var(--componentBackgroundColor);
	background-color: var(--accentGray);
	cursor: default;
	transition: background-color 0.2s ease-in-out;
}


.displayPlus {
	align-items: center;
	display: flex;
}

.displayPlus img {
	width: 12px;
	/*filter: var(--filterConverterForDarkSVGtoWhite);*/
	margin-right: 8px;
}
