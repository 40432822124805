.wrapper {
    position: fixed;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    left: 0;
    top: 0;
    background-color: rgba(1,1,1,0.6);
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
}

.wrapper.wrapperOpened {
    opacity: 1;
    pointer-events: visible;
}

.rightContainer {
    right: -100%;
    position: absolute;
    transition: right 300ms ease-in-out;
}

.rightContainer {
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: var(--componentBackgroundColor);
    box-shadow: var(--defaultBoxShadow);
    z-index: 5;
    top: 0;
    /*background-color: var(--componentBackgroundColor);*/
}
.menuToggle {
    opacity: 0;
    z-index: 5;
}

.menuToggle:checked ~ .menuButton > span {
    transform: rotate(45deg);
}
.menuToggle:checked ~ .menuButton > span::before {
    top: 0;
    width: 100%;
    height: 25px;
    border-radius: 0;
    transform: rotate(0);
    background-color: white;
}
.menuToggle:checked ~ .menuButton > span::after {
    top: 0;
    width: 100%;
    height: 25px;
    border-radius: 0;
    transform: rotate(90deg);
    background-color: white;
}

.menuButton {
    display: flex;
    align-items: center;
    position: fixed;
    top: 35px;
    right: 0;

    width: 26px;
    height: 26px;

    cursor: pointer;
    z-index: 3;
}

.menuButton > span,
.menuButton > span::before,
.menuButton > span::after {
    display: block;
    position: absolute;

    width: 5px;
    height: 5px;
    border-radius: 100%;

    background-color: #616161;

    transition-duration: .25s;
}
.menuButton > span::before {
    content: '';
    top: -8px;
}
.menuButton > span::after {
    content: '';
    top: 8px;
}

.hamburger .menuToggle {
    display: block;
    /*position: absolute;*/
    z-index: 3;
}

.hamburger .menuToggle:checked ~ .rightContainer {
    right: 0;
    z-index: 4;
}

.hamburger .menuToggle + label {
    z-index: 4;
    transition: right 300ms ease-in-out;
}

.hamburger .menuToggle:checked + label {
    right: 250px;
    transition: right 300ms ease-in-out;
}
