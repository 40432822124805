.loader {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--componentBackgroundColor);
    z-index: 1000;
}

.loader .spinner {
    pointer-events: none;
    width: 5.5em;
    height: 5.5em;
    border: 0.7em solid transparent;
    border-color: var(--accentGray);
    border-top-color: var(--accentCrimsonColor);
    opacity: 0.7;
    border-radius: 50%;
    animation: loadingSpinnerRotate 1s linear infinite;
}

.defaultLoader {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
}

.defaultLoader .spinner {
    pointer-events: none;
    width: 4em;
    height: 4em;
    border: 0.6em solid transparent;
    border-color: var(--accentGray);
    border-top-color: var(--accentCrimsonColor);
    opacity: 0.85;
    border-radius: 50%;
    animation: loadingSpinnerRotate 1s linear infinite;
}

@keyframes loadingSpinnerRotate {
    100% {
        transform: rotate(360deg);
    }
}
