.lockIcon {
    width: 25px;
    filter: var(--filterConverterForDarkSVGtoGray);
}

.lockIcon.navigation {
    position: absolute;
    height: 18px;
    width: 18px;
    right: 16px;
    margin-top: 10px;
}

.lockIconUserTasks {
    filter: var(--filterConverterForDarkSVGtoGray);
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.4;
    height: 400px;
}

@media screen and (max-width: 1600px) {
    .lockIcon.navigation {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 15px;
        margin-top: 10px;
    }
}
