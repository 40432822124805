.container {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
}

.container p {
    font-size: 24px;
    font-family: var(--componentFontFamily);
    font-weight: 600;
}

.container p:not(:last-child) {
    /*margin-right: 30px;*/
}

.batteryContainer {
    position: relative;
    transform: rotate(90deg);
}

.progressState {
    position: absolute;
    width: 17px;
    /*height: 50%;*/
    background-color: white;
    z-index: 2;
}

.battery p:first-child {
    transform: rotate(-90deg);
    font-size: 14px;
    position: absolute;
    z-index: 5;
    left: -7px;
    top: 6px;
    width: 28px;
    height: 16px;
    text-align: center;
}

/*.battery p:last-child {*/
/*    transform: rotate(-90deg);*/
/*    font-size: 14px;*/
/*    position: absolute;*/
/*    z-index: 5;*/
/*    left: 10px;*/
/*    top: 6px;*/
/*    width: 28px;*/
/*    height: 16px;*/
/*}*/

.battery{
    background: linear-gradient(#53ff14, #a6ff00, #ff9900, #ff3838);
    top: 4px;
    left: 1px;
    position: absolute;
    width: 16px;
    height: 28px;
    /*background: linear-gradient(#53ff14, #ff3838);*/
}

.batteryImage {
    width: 18px;
    z-index: 3;
}

.info {
    position: absolute;
    top: 40px;
    background-color: rgba(1,1,1,0.9);
    color: #d7d7d7;
    border-radius: 6px;
    padding: 8px;
    width: max-content;
    /*visibility: hidden;*/
    opacity: 0;
    transition: opacity var(--fastAnimationDuration) ease-in-out;
    z-index: 2;
    pointer-events: none;
}

.info h1 {
    color: white;
    font-size: 16px;
    /*margin-bottom: 5px;*/
    font-family: var(--componentFontFamily);
}

.info p {
    font-size: 14px;
    font-family: var(--daysFontFamily);
    font-weight: 500;
}

.name {
    display: flex;
    justify-content: space-between;
}

.name p {
    color: white;
    font-size: 16px;
    font-family: var(--componentFontFamily);
    font-weight: 600;
    letter-spacing: 1px;
    margin-left: 60px;
}

.row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.row p:first-child {
    margin-right: 40px;
}

.avatar {
    border: none;
    width: 30px;
    transition: opacity 200ms ease-in-out;
}

.avatar:hover + .info {
    opacity: 1;
}

.avatar:hover {
    opacity: 0.5;
}

.reversed {
    left: -250px;
    z-index: 4;
}

@media screen and (max-width: 1600px) {
    .info {
        left: -250px;
        z-index: 4;
        top: 55px;
    }
    .name h1 {
        margin-right: 60px;
    }
}
